import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";

const QuoteRequestMiddleware = (store) => (next) => async (action) => {
  const baseUrl = `${microServices.base}/v1/company/quote-requests`;
  let endpoint;

  switch (action.type) {
    case "FETCH_QUOTE_REQUESTS":
      endpoint = baseUrl;
      if (action.payload.params) {
        const queryString = new URLSearchParams(
          action.payload.params
        ).toString();
        endpoint += "?" + queryString;
      }

      store.dispatch(isLoader(true));

      APIservice.get(endpoint)
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: "SET_QUOTE_REQUESTS",
              payload: { data: response.data.data },
            });
          }
        })
        .catch((error) => {
          let err = {
            componentName: "QuoteRequests",
            statusCode: error.response.status,
            data: error.response.data,
          };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });

      break;

    case "FETCH_QUOTE_REQUEST":
      endpoint = `${baseUrl}/${action.payload.id}`;

      store.dispatch(isLoader(true));

      APIservice.get(endpoint)
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: "SET_QUOTE_REQUEST",
              payload: { data: response.data.data },
            });
          }
        })
        .catch((error) => {
          let err = {
            componentName: "QuoteRequest",
            statusCode: error.response.status,
            data: error.response.data,
          };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });

      break;

    case "ADD_QUOTE_REQUEST":
      endpoint = baseUrl;

      store.dispatch(isLoader(true));

      APIservice.post(endpoint, action.payload.data)
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: action.payload.successMessage,
            },
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: action.payload.errorMessage,
              },
            });
          } else {
            let err = {
              componentName: "AddQuoteRequest",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });

      break;

    case "ACCEPT_QUOTE_REQUEST":
      endpoint = `${baseUrl}/${action.payload.id}/accept`;

      store.dispatch(isLoader(true));

      APIservice.put(endpoint, action.payload.data)
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: action.payload.successMessage,
            },
          });
        })
        .catch((error) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "danger",
              message: error.response.data?.message || action.payload.errorMessage,
            },
          });
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });

      break;

    case "REJECT_QUOTE_REQUEST":
      endpoint = `${baseUrl}/${action.payload.id}/reject`;

      store.dispatch(isLoader(true));

      APIservice.put(endpoint, action.payload.data)
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: action.payload.successMessage,
            },
          });
        })
        .catch((error) => {
          let err = {
            componentName: "RejectQuoteRequest",
            statusCode: error.response.status,
            data: error.response.data,
          };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;

    default:
      break;
  }

  return next(action);
};

export default QuoteRequestMiddleware;
