import axios from "axios";
import { microServices, servicesUrl } from "../config/global";
import apiService from "../redux/apiService";
import { handleValidations } from "./cardValidation";
import axiosRetry from "axios-retry";
import { GATEWAYS, PAYMENT_GATEWAY } from "./paymentConstUtil";
import { useContext } from "react";
import { LanguageContext } from "../components/containers/Language";

let retries = 1;

export const getCardpointeToken = async () => {
  let endpoint =
    servicesUrl.base_cardpoint + "cardsecure/api/v1/retrievePublicKey";
  return await get(endpoint).then((response) => {
    return response;
  });
};

export function cardpointeFormValidation(tilledForm) {
  let formError;
  if (tilledForm.type == "card") {
    for (const key in tilledForm?.card) {
      let err = handleValidations(key, tilledForm?.card[key]);
      formError = {
        ...formError,
        ...err,
      };
    }
    let err5 = handleValidations(
      "exp_month_year",
      tilledForm?.card?.exp_month + "/" + tilledForm?.card?.exp_year
    );
    formError = {
      ...formError,
      ...err5,
    };

    let err3 = handleValidations("email", tilledForm?.billing_details?.email);
    let err4 = handleValidations("name", tilledForm?.billing_details?.name);

    formError = {
      ...formError,
      ...err3,
      ...err4,
    };
  }
  for (const key in tilledForm?.billing_details?.address) {

    let err2 = handleValidations(key, tilledForm?.billing_details?.address[key]);
    formError = {
        ...formError,
        ...err2
    };
}
  if (tilledForm.type == "ach_debit") {
    for (const key in tilledForm?.ach_debit) {
      let err3 = handleValidations(key, tilledForm?.ach_debit[key]);
      formError = {
        ...formError,
        ...err3,
      };
    }
  }
  return formError;
}

export const getPaymentToken = async (formData) => {
  let endpoint = servicesUrl.base_cardpoint + "cardsecure/api/v1/ccn/tokenize";
  return await post(endpoint, formData).then((response) => {
    return response;
  });
};

export const setTokenPayload = (paymentMethod) => {
  if (paymentMethod?.type == "card") {
    let accNo = paymentMethod?.card?.number;
    return {
      cvv: paymentMethod?.card?.cvc,
      useexpiry:true,
      usecvv:true,
      account: accNo.replace(/\s+/g, ""),
      expiry:paymentMethod?.card?.exp_month+''+paymentMethod?.card?.exp_year
    };
  }
  if (paymentMethod?.type == "ach_debit") {
    return {
      account:
        paymentMethod?.ach_debit?.routing_number +
        "/" +
        paymentMethod?.ach_debit?.account_number,
    };
  }
};

function createHttpCardpointe() {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CARDPOINT_BASE_URL,
  });
  axiosRetry(axiosInstance, { retries: retries });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.response) {
        const { config, message } = error;

        if (
          error.response.status === 401 ||
          error.response.data.code === "token_not_valid" ||
          error.response.statusText === "Unauthorized"
        ) {
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    }
  );
  return axiosInstance;
}

function get(url, params) {
  return createHttpCardpointe().get(url, { params });
}

function post(url, data) {
  return createHttpCardpointe().post(url, data);
}

export const _autopaylabel = (req) => {
  const { localised } = useContext(LanguageContext);
  if (req.payment_gateway?.id == PAYMENT_GATEWAY.STRIPE) {
    return localised.pleaseFillYourCardDetails;
  }
  if (req.payment_gateway?.id == PAYMENT_GATEWAY.TILLED) {
    return localised.pleaseFillYourTilledDetails;
  }
  if (req.payment_gateway?.id == PAYMENT_GATEWAY.CARDPOINTE) {
    return localised.paymentInfo;
  }
};

export const _autopayAggreementlabel = (req) => {
  const { localised } = useContext(LanguageContext);
  if (req.payment_gateway?.id == PAYMENT_GATEWAY.STRIPE) {
    return localised.setupStripeAutopay;
  }
  if (req.payment_gateway?.id == PAYMENT_GATEWAY.TILLED) {
    return localised.setupTilledAutopay;
  }
  if (req.payment_gateway?.id == PAYMENT_GATEWAY.CARDPOINTE) {
    return localised.setUpCardpointeAutopay;
  }
};

export const _autopayDisablelabel = (req) => {
  const { localised } = useContext(LanguageContext);
  if (req?.payment_gateway == GATEWAYS[1].label) {
    return localised.disablStripeAutopay;
  }
  if (req?.payment_gateway == GATEWAYS[2].label) {
    return localised.disableTilledAutopay;
  }
  if (req?.payment_gateway == GATEWAYS[3].label) {
    return localised.disableCardpointeAutopay;
  }
};

export const _autopayEditlabel = (req) => {
  const { localised } = useContext(LanguageContext);

  if (req?.payment_gateway == GATEWAYS[1].label) {
    return localised.enableStripeAutopay;
  }
  if (req?.payment_gateway == GATEWAYS[2].label) {
    return localised.enableTilledAutopay;
  }
  if (req?.payment_gateway == GATEWAYS[3].label) {
    return localised.enableCardpointeAutopay;
  }
};

export const boardedStatus = [
  'BOARDED',
  'LIVE'
];

export const boardingInProgress = [
  'BOARDING',
  'QUALIFY',
  'UNDER',
];

export const boardingCancelled = [
  'CANCELLED',
  'DECLINED'
]

export const boardingPending = [
  'INPROG',
  'OFS'
];

export const gatewayStatus = [
  'BOARDED',
];

export const signatureStatus = [
  'SIGNED'
];