export const updateBillingConfig = (data) => {
  return {
      type: 'UPDATE_BILLING_CONFIG',
      payload: data
  };
}

export const getBillingConfig = (data) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_BILLING_CONFIG',
    payload:data
  });
}

export const getPaymentMode = (data) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_PAYMENT_MODE',
    payload:data
  });
}

export const updatePaymentMode = (data) => {
  return { 
      type: 'UPDATE_PAYMENT_MODE',
      payload: data
  };
}

export const getPaymentType = (data) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_PAYMENT_TYPE',
    payload:data
  });
}

export const getPaymentModeList = (data) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_PAYMENT_MODE_LIST',
    payload:data
  });
}

export const getPaymentMethod = (data) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_PAYMENT_METHOD',
    payload:data
  });
}

export const paymentConfig = (data) => {
  return {
    type: "STORE_PAYMENT_CONFIG",
    payload: data,
  };
};

export const getCompanyWallet = (data) => {
  return {
    type: "FETCH_COMPANY_WALLET",
    payload: data,
  };
};

export const deleteCompanyWallet = (data) => async (dispatch) =>{
  dispatch({
    type: 'DELETE_COMPANY_WALLET',
    payload:data
  });
}

export const getCompanyStrip = (data) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_COMPANY_STRIP_DETAIL',
    payload:data
  });
}

export const enableDisableAutopay = (data) =>{
  return {
      type: 'ENABLE_DISABLE_TILLED_AUTOPAY',
      payload: data,
  };
}

export const getCompanyTilledDetails = (data) => {
  return {
    type: "FETCH_COMPANY_TILLED_DETAILS",
    payload: data,
  };
};

export const deleteCompanyTilled = (data) => async (dispatch) =>{
  dispatch({
    type: 'DELETE_COMPANY_TILLED_DETAILS',
    payload:data
  });
}

export const createMerchantAccount = (data) => {
  return {
    type: "CREATE_MERCHANT_ACCOUNT",
    payload: data,
  };
};

export const getTilledProductCodes = (data) => {
  return {
    type: "FETCH_TILLED_PRODUCT_CODES",
    payload: data,
  };
};

export const upadteProductCodes = (data) => {
  return { 
      type: 'UPDATE_PRODUCT_CODES',
      payload: data
  };
}

export const updateCompanyStrip = (data) => {
  return { 
      type: 'UPDATE_COMPANY_STRIP',
      payload: data
  };
}

export const createCompanyStrip = (data) => {
  return {
      type: 'STORE_COMPANY_STRIP',
      payload: data,
  };
}

export const addInvoiceToQb = (data) => { 
  return {
      type: 'ADD_INVOICE_TO_QB',
      payload: data,
  };
}

export const markInvoiceFullyPaid = (data) => {
  return {
      type: 'MARK_INVOICE_FULLYPAID',
      payload: data,
  };
}

export const markInvoiceUnpaid = (data) => {
  return {
      type: 'MARK_INVOICE_UNPAID',
      payload: data,
  };
}

export const deleteInvoice = (data) => {
  return {
      type: 'DELETE_INVOICE',
      payload: data,
  };
}

export const markAsFullyPaid = (data) => {
  return {
      type: 'UPDATE_FULLY_PAID_STATUS',
      payload: data,
  };
}

export const deletePayRoll = (data) => {
  return {
      type: 'DELETE_PAYROLL',
      payload: data,
  };
}

export const getTransactionDetails = (data) => {
  return {
      type: 'GET_TRANSACTION_DETAILS',
      payload: data,
  };
}

export const saveTransactionDetails = (data) => {
  return {
      type: 'SAVE_TRANSACTION_DETAILS',
      payload: data,
  };
}

export const sendEmail = (data) => {
  return {
      type: 'SEND_EMAIL',
      payload: data,
  };
}

export const getPaymentGateways = (data) => {
  return {
      type: 'GET_PAYMENT_GATEWAYS',
      payload: data,
    };
  }

export const saveInvoiceTransactionDetails = (data) => {
  return {
      type: 'SAVE_INVOICE_TRANSACTION_DETAILS',
      payload: data,
  };
}

export const getPayrollListing = (data) => {
  return {
      type: 'CREATE_PAYROLL_LISTING',
      payload: data,
  };
}
export const printPDF = (data) => {
  return {
      type: 'PRINT_PDF',
      payload: data,
  };
}

  export const emailInvoices= (data) => {
  return {
      type: 'EMAIL_INVOICES',
      payload: data,
  };
}

export const updateInvoiceDetails = (data) => {
  return {
      type: 'UPDATE_INVOICE_DETAILS',
      payload: data
    }
}

export const getAutoPayRequest = (data) => {
  return {
    type: 'GET_AUTO_PAY_REQUEST',
    payload: data,
  };
}

export const declineAutoPayRequest = (data) => {
  return {
    type: 'DECLINE_AUTO_PAY_REQUEST',
    payload: data,
  };
}

export const saveTilledAutoPayRequest = (data) => {
  return {
    type: 'SAVE_TILLED_AUTO_PAY_REQUEST',
    payload: data,
  };
}

export const saveStripeAchAutoPayRequest = (data) => {
  return {
    type: 'SAVE_ACH_STRIPE',
    payload: data,
  };
}

export const confirmStripeAchPayRequest = (data)=>{
  return {
    type:'CONFIRM_ACH_STRIPE',
    payload:data,
  }
}

export const disableAutoPayRequest = (data)=>{
  return {
    type:'DISABLE_AUTO_PAY_REQUEST',
    payload:data,
  }
}

export const disableProfileAutoPayRequest = (data)=>{
  return {
    type:'DISABLE_PROFILE_AUTO_PAY_REQUEST',
    payload:data,
  }
}
export const downloadExcelReport = (data) => {
  return {
      type: 'DOWNLOAD_EXCEL_REPORT',
      payload: data
  }
}

export const downloadCreateInvoiceExcel = (data) => {
  return {
      type: 'DOWNLOAD_CREATE_INVOICE_EXCEL',
      payload: data
  }
}

export const getStripeAutoPayData = (data)=>{
  return {
    type:'GET_STRIPE_AUTO_PAYMENT_DATA',
    payload:data,
  }
}
export const getTilledAutoPayData = (data) => {
  return {
      type: 'GET_TILLED_AUTO_PAYMENT_DATA',
      payload: data
  }
}

export const updateTilledAutoPayRequest = (data) => {
  return {
    type: 'UPDATE_TILLED_AUTO_PAY_REQUEST',
    payload: data,
  };
}

export const updateStripeAchPayRequest = (data) =>{
  return {
    type:'UPDATE_ACH_STRIPE_METHOD',
    payload:data,
  }
}

export const downloadAgreement=(data) => {
  return {
    type:'DOWNLOAD_AGREEMENT_FILE',
    payload:data,
  }
}

export const enableAutoPayRequest=(data)=>{
  return {
    type:'ENABLE_AUTOPAY',
    payload:data,
  }
}

export const getQuickBook = (data,reqtype) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_QUICK_BOOK_DETAIL',
    payload:data,
    reqtype:reqtype,
  });
}

export const connectToQuickBook = (data) => {
  return {
      type: 'STORE_QUICK_BOOK_CALLBACK',
      payload: data,
  };
}

export const addPayrollToQb = (data) => {
  return {
      type: 'ADD_PAYROLL_TO_QB',
      payload: data,
  };
}

export const resolveInvCliSerQbc = (data) => { 
  return {
      type: 'RESOLVE_INV_xCLI_SER_QB',
      payload: data
  };
}

export const resolveInvCliSerQb = (data,apiType) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'RESOLVE_INV_CLI_SER_QB',
    payload:data,
    apiType:apiType,
  });
}

export const resolvePayroll = (data) => { 
  console.log(data);
  return {
      type: 'RESOLVE_PAYROLL',
      payload: data
  };
}

export const downloadCreatePayrollExcelReport = (data) => {
  return {
      type: 'DOWNLOAD_CREATE_PAYROLL_EXCEL_REPORT',
      payload: data
  }
}

export const getQbpayrollCoa =(data)=>{
  return { 
    type: 'GET_QB_PAYROLL_COA',
    payload: data,
  };
}

export const companyQbPayrollCoa =(data)=>{
  return{
      type:'STORE_QB_PAYROLL_COA_WO_ITEM',
      payload:data
  };
}

export const saveCardpointAutoPayRequest = (data) => {
  return {
    type: 'SAVE_CARDPOINTE_AUTO_PAY_REQUEST',
    payload: data,
  };
}

export const getCardpointeAutoPayData = (data) => {
  return {
      type: 'GET_CARDPOINTE_AUTO_PAYMENT_DATA',
      payload: data
  }
}

export const updateCardpointeAutoPayRequest = (data) => {
  return {
    type: 'UPDATE_CARDPOINTE_AUTO_PAY_REQUEST',
    payload: data,
  };
}
export const getCompanyCardpointeDetails = (data) => {
  return {
    type: "FETCH_COMPANY_CARDPOINTE_DETAILS",
    payload: data,
  };
};
export const deleteCompanyCardpointe = (data) => async (dispatch) =>{
  dispatch({
    type: 'DELETE_COMPANY_CARDPOINTE_DETAILS',
    payload:data
  });
}

export const createCardpointeAccount = (data) => {
  return {
    type: "CREATE_MERCHANT_ACCOUNT_CARDPOINTE",
    payload: data,
  };
};

export const syncCardpointeAccount = (data) => {
  return {
    type: "SYNC_MERCHANT_ACCOUNT_CARDPOINTE",
    payload: data,
  };
};

export const getOnboardingurl =(data)=>{
  return {
    type: "GET_CARDPOINTE_ONBORDING_URL",
    payload: data,
  };
}

export const upadtePropelr = (data) => {
  return { 
      type: 'UPDATE_PROPELR_DATA',
      payload: data
  };
}
export const getAgreementRequest = (data) => {
  return {
    type: "GET_AGREEMENT_REQUEST",
    payload: data,
  };
};
export const declineAgreementRequest = (data) => {
  return {
    type: "DECLINE_AGREEMENT_REQUEST",
    payload: data,
  };
};
export const accecptAgreementRequest = (data) => {
  return {
    type: "ACCEPT_AGREEMENT_REQUEST",
    payload: data,
  };
};
export const billingAgreementDownload = (data) => {
  return {
    type: "BILLING_AGREEMENT_DOWNLOAD",
    payload: data,
  }
};

export const submitCardpointeApiCredentils = (data) => {
  return {
    type: "API_CREDENTILAS_CARDPOINTE",
    payload: data,
  };
};

export const saveCardpointeRcPaymentRequest = (data) => {
  return {
    type: 'SAVE_CARDPOINTE_RC_PAYMENT_REQUEST',
    payload: data,
  };
}

export const invoicePaymentViaCardpointe = (data) => {
  return {
    type: "RC_INVOICE_CARDPOINTE_PAYMENT",
    payload: data,
  };
};

export const updatePayrollDetails = (data) => {
  return {
      type: 'UPDATE_PAYROLL_DETAILS',
      payload: data
    }
}

export const managerEnableAutoPay=(data)=>{
  return {
    type:'MANAGER_ENABLE_AUTOPAY',
    payload:data,
  }
}

export const acceptAgreement = (data) => {
  return {
    type: 'ACCEPT_AGREEMENT',
    payload: data,
  };
}
