const initState = {
  inspections:[],
  categories:[],
  regionAreaAccess:[],
  category:{},
  items:[],
  template:[],
  inspectionData:[],
  callbackCount: 0
};
  
  const inspection = (state = initState, action) => {

    switch (action.type) {

      case 'SET_INSPECTION_REGION_DATA': {
        return {
          ...state,
          inspTemplateData : action.payload,
        };
      }
      
      case 'CLEAR_INSPECTION_REGION_DATA': {
        return {
          ...state,
          inspTemplateData:[],
        };
      }

      case 'SET_TEMPLATE_REGION_AREA_ACCESS': {
        return {
          ...state,
          regionAreaAccess: action.payload.data
        };
      }

      case 'CLEAR_REGION_AREA_ACCESS': {
        return {
          ...state,
          regionAreaAccess: null
        };
      }

      case 'SET_TEMPLATE_CATEGORIES':{
        return{
          ...state,
          categories: action.payload.data.data,
          itemCount: action.payload.data.meta

        }
      }

      case 'CLEAR_TEMPLATE_CATEGORIES':{
        return{
          ...state,
          categories:[],
          itemCount:[]
        }
      }

      case 'SET_TEMPLATE_CATEGORY':{
        return{
          ...state,
          category: action.payload.data
        }
      }

      case 'CLEAR_TEMPLATE_CATEGORY':{
        return{
          ...state,
          category:[]
        }
      }

      case 'SET_TEMPLATE_ITEMS':{
        return{
          ...state,
          items: action.payload.data
        }
      }

      case 'CLEAR_TEMPLATE_ITEMS':{
        return{
          ...state,
          items:[]
        }
      }

      case "SET_INSPECTION_LIST":
      return {
        ...state,
        inspections: action.payload,
      };

      case "CLEAR_INSPECTION_LIST":
      return {
        ...state,
        inspections: null,
      };

      case 'SET_INSPECTION_REPORT':{
        return{
          ...state,
          inspectionReport: action.payload.data
        }
      }

      case 'CLEAR_INSPECTION_REPORT':{
        return{
          ...state,
          inspectionReport:[]
        }
      }
      case 'SET_TEMPLATE_DETAIL':{
        return {
          ...state,
          inspectionTemplate : action.payload.data
        };
      }
      case 'CLEAR_TEMPLATE_DETAIL':{
        return {
          ...state,
          inspectionTemplate : []
        };
      }

      case 'SET_FAILED_ITEMS_CATEGORY_WISE':{
        return {
          ...state,
          failedItemsCategoryWise : action.payload.data.data
        };
      }
      case 'CLEAER_FAILED_ITEMS_CATEGORY_WISE':{
        return {
          ...state,
          failedItemsCategoryWise : []
        };
      }

      case 'SET_INSPECTION_DATA':{
        return {
          ...state,
          inspectionData : action.payload.data
        };
      }
      case 'CLEAR_INSPECTION_DATA':{
        return {
          ...state,
          inspectionData : []
        };
      }

      case 'SET_CALLBACK_INSP_COUNT':{
        return {
          ...state,
          callbackCount : action.payload.data
        };
      }

      case 'SET_ENABLE_INSPECTION':{
        return {
          ...state,
          enableInspection : action.payload.data
        };
      }
      case 'CLEAR_ENABLE_INSPECTION':{
        return {
          ...state,
          enableInspection :[]
        };
      }


      case 'SET_HK_FAILED_ITEMS':{
        return {
          ...state,
          hkFailedItems : action.payload.data
        };
      }
      case 'CLEAR_HK_FAILED_ITEMS':{
        return {
          ...state,
          hkFailedItems : null
        };
      }
      

      case 'SET_REMOVED_FAILED_ITEMS':{
        return {
          ...state,
          removeFailedItems : action.payload.data
        };
      }
      case 'CLEAR_REMOVED_FAILED_ITEMS':{
        return {
          ...state,
          removeFailedItems : null
        };
      }

      case 'SET_INSPECTION_ALL_PHOTOS':{
        return {
          ...state,
          inspectionPhotos : action.payload.data
        };
      }
      case 'CLEAR_INSPECTION_ALL_PHOTOS':{
        return {
          ...state,
          inspectionPhotos : null
        };
      }

      
      default:
        return state;
  }
};

export default inspection;
