import { useKeycloak } from '@react-keycloak/web';

import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loader from '../atoms/Loader';
import RedirectHandler from './RedirectHandler';
import Logout from '../pages/Authentication/Logout';
import CleanerJobsAndRevenueReport from '../pages/Cleaner/JobsAndRevenue';

const AdminUsers = React.lazy(() => import('../pages/AdminUsers/Index'));
const HkGroups = React.lazy(() => import('../pages/Cleaner/HkGroups'));
const CleanerProfile = React.lazy(() => import('../pages/Cleaner/Profile'));
const CleanerArea = React.lazy(() => import('../pages/CleanerArea'));
const CleanerRegion = React.lazy(() => import('../pages/CleanerRegion'));
const Cleaners = React.lazy(() => import('../pages/Cleaners'));
const Configuration = React.lazy(() => import('../pages/Configuration'));
const UnauthoriseAccess = React.lazy(() => import('../pages/Erorr/NoAccess'));
const ManagerArea = React.lazy(() => import('../pages/ManagerArea'));
const ManagerRegion = React.lazy(() => import('../pages/ManagerRegion'));
const ManagerStaffManagement = React.lazy(() => import('../pages/ManagerStaffManagement'));
const PropertyManagers = React.lazy(() => import('../pages/PropertyManagers'));
const ServicePage = React.lazy(() => import('../pages/Services'));
const StaffManagement = React.lazy(() => import('../pages/StaffManagement'));
const CleanerSignup = React.lazy(() => import('../pages/UserSignup/CleanerSignup'));
const ManagerSignup = React.lazy(() => import('../pages/UserSignup/ManagerSignup'));
const Signup = React.lazy(() => import('../pages/UserSignup/Signup'));
const SignupThankyou = React.lazy(() => import('../pages/UserSignup/SignupThankyou'));
const CleanerMergeTrackReport = React.lazy(() => import ( '../pages/CleanerMergeTrackReport'))
const PrivateRoute = React.lazy(() => import('./PrivateRoute'));
const PublicRoute = React.lazy(() => import('./PublicRoute'));
const CleanerProperties = React.lazy(() => import('../pages/Cleaner/Properties'));
const CleanerClieantList = React.lazy(() => import('../pages/ClientList/CleanerClieantList'));
const Branding = React.lazy(() => import('../pages/Cleaner/Branding'));
const ManagerCleaners = React.lazy(() => import('../pages/Manager/Cleaners'));
const ManagerProperties = React.lazy(() => import('../pages/Manager/Properties'));
const ManagerInventory = React.lazy(() => import('../pages/Manager/Inventory'));
const CleanerInventory = React.lazy(() => import('../pages/Cleaner/Inventory'));
const CleanerMyAccount = React.lazy(() => import('../pages/Cleaner/MyAccount'));
const ManagerMyAccount = React.lazy(() => import('../pages/Manager/MyAccount'));
const CleanerPriceConfig = React.lazy(() => import('../pages/Cleaner/PropertyPriceConfig'));
const CleanerServiceConfig = React.lazy(() => import('../pages/Cleaner/PropertyServiceConfig'));
const ManagerPriceConfig = React.lazy(() => import('../pages/Manager/PropertyPriceConfig'));
const CleanerStaffMyAccount = React.lazy(() => import('../pages/Cleaner/StaffMyAccount'));
const ManagerStaffMyAccount = React.lazy(() => import('../pages/Manager/StaffMyAccount'));
const ManagerServiceConfig = React.lazy(() => import('../pages/Manager/PropertyServiceConfig'));
const ManagerPropertyMessagingConfig = React.lazy(() => import('../pages/Manager/PropertyMessagingConfig'));
const CleanerPropertyMessagingConfig = React.lazy(() => import('../pages/Cleaner/PropertyMessagingConfig'));
const ManagerAppointmentMessaging = React.lazy(() => import('../pages/Manager/AppointmentMessaging'));
const CleanerAppointmentMessaging = React.lazy(() => import('../pages/Cleaner/AppointmentMessaging'));
const AdminMyAccount = React.lazy(() => import('../pages/AdminPages/MyAccount'));
const AdminChangePassword = React.lazy(() => import('../pages/AdminPages/ChangePassword'));

const CleanerAppointments = React.lazy(() => import('../pages/CleanerAppointments'));
const CleanerEditAppointments = React.lazy(() => import('../pages/CleanerAppointments/EditAppointments'));

const ManagerAppointments = React.lazy(() => import('../pages/ManagerAppointments'));
const ManagerEditAppointments = React.lazy(() => import('../pages/ManagerAppointments/EditAppointments'));
const ScheduleAppointments = React.lazy(() => import('../pages/CleanerAppointments/ScheduleAppointments'));

const UploadProperties = React.lazy(() => import('../pages/Cleaner/UploadProperties'));
const ManagerScheduleAppointment = React.lazy(() => import('../pages/ManagerAppointments/ScheduleAppointments'));
const ManagerUploadProperties = React.lazy(() => import('../pages/Manager/UploadProperties'));

const CleanerInspections = React.lazy(() => import('../pages/Cleaner/Inspections'));
const CleanerInspectionTemplate = React.lazy(() => import('../pages/Cleaner/InspectionTemplate'));
const CleanerPropertyInspection = React.lazy(() => import('../pages/Cleaner/PropertyInspection'));
const CleanerFacilityInspection = React.lazy(() => import('../pages/Cleaner/FacilityInspection'));

const ManagerInspectionTemplate = React.lazy(() => import('../pages/Manager/InspectionTemplate'));
const ManagerInspections = React.lazy(() => import('../pages/Manager/Inspections'));
const ManagerPropertyInspection = React.lazy(() => import('../pages/Manager/PropertyInspection'));
const ManagerFacilityInspection = React.lazy(() => import('../pages/Manager/FacilityInspection'));

const ServiceAutomationPage = React.lazy(() => import('../pages/ServiceAutomationPage'));

const CleanerServiceChecklist = React.lazy(() => import('../pages/Cleaner/ServiceChecklist'));
const ManagerServiceChecklist = React.lazy(() => import('../pages/Manager/ServiceChecklist'));
const CleanerWorkOrderItems = React.lazy(() => import('../pages/Cleaner/WorkOrderItems'));
const ManagerWorkOrderItems = React.lazy(() => import('../pages/Manager/WorkOrderItems'));
const ScheduleSingleDay = React.lazy(() => import('../pages/CleanerAppointments/ScheduleSingleDay'));
const ManagerScheduleSingleDay = React.lazy(() => import('../pages/ManagerAppointments/ScheduleSingleDay'));
const ManagerInternalServices = React.lazy(() => import('../pages/Manager/InternalServices'));
const ManagerServicesAutomation = React.lazy(() => import('../pages/Manager/ServicesAutomation'));
const ManagerScheduleInternalAppointments = React.lazy(() => import('../pages/ManagerAppointments/ScheduleInternalAppointments'));
const CleanerWorkOrders = React.lazy(() => import('../pages/Cleaner/WorkOrders'));
const ManagerWorkOrders = React.lazy(() => import('../pages/Manager/WorkOrders'));
const CleanerUploadSchedule = React.lazy(() => import('../pages/CleanerAppointments/UploadSchedule'));
const CleanerUploadedScheduleList = React.lazy(() => import('../pages/CleanerAppointments/UploadedScheduleList'));
const CleanerAssignStaff = React.lazy(() => import('../pages/Cleaner/AssignStaff'));
const CleanerCancelAppointments = React.lazy(() => import('../pages/CleanerAppointments/CancelAppointments'));
const ManagerUploadSchedule = React.lazy(() => import('../pages/ManagerAppointments/UploadSchedule'));
const ManagerUploadedScheduleList = React.lazy(() => import('../pages/ManagerAppointments/UploadedScheduleList'));
const ManagerAssignStaff = React.lazy(() => import('../pages/Manager/AssignStaff'));
const ManagerCancelAppointments = React.lazy(() => import('../pages/ManagerAppointments/CancelAppointments'));
const CleanerUploadEvolve = React.lazy(() => import('../pages/CleanerAppointments/UploadEvolve'));

const CleanerSupportTasks = React.lazy(() => import('../pages/Cleaner/SupportTasks'));
const ManagerServiceRequest = React.lazy(() => import('../pages/Manager/ServiceRequest'));
const CleanerServiceRequest = React.lazy(() => import('../pages/Cleaner/ServiceRequest'));
const CleanerAppointmentsTasks = React.lazy(() => import('../pages/Cleaner/AppointmentsTasks'));

const ManagerAirbnb = React.lazy(() => import('../pages/Manager/Integrations/Airbnb'));
const ManagerAlbamaVhr = React.lazy(() => import('../pages/Manager/Integrations/AlbamaVhr'));
const ManagerBookingSync = React.lazy(() => import('../pages/Manager/Integrations/BookingSync'));
const ManagerDirectPms = React.lazy(() => import('../pages/Manager/Integrations/DirectPms'));
const ManagerBookingCom = React.lazy(() => import('../pages/Manager/Integrations/BookingCom'));
const ManagerLivrez = React.lazy(() => import('../pages/Manager/Integrations/Livrez'));
const ManagerRezkey = React.lazy(() => import('../pages/Manager/Integrations/Rezkey'));
const ManagerGuesty = React.lazy(() => import('../pages/Manager/Integrations/Guesty'));
const ManagerHospitable = React.lazy(() => import('../pages/Manager/Integrations/Hospitable'));
const ManagerHostaway = React.lazy(() => import('../pages/Manager/Integrations/Hostaway'));
const ManagerHostawayDirectSetup = React.lazy(() => import('../pages/Manager/Integrations/HostawayDirectSetup'));
const ManagerHostawayDirect = React.lazy(() => import('../pages/Manager/Integrations/HostawayDirect'));
const ManagerHostfully = React.lazy(() => import('../pages/Manager/Integrations/Hostfully'));
const ManagerHostify = React.lazy(() => import('../pages/Manager/Integrations/Hostify'));
const ManagerHoufy = React.lazy(() => import('../pages/Manager/Integrations/Houfy'));
const ManagerIgms = React.lazy(() => import('../pages/Manager/Integrations/Igms'));
const ManagerIvacation = React.lazy(() => import('../pages/Manager/Integrations/Ivacation'));
const ManagerJaniis = React.lazy(() => import('../pages/Manager/Integrations/Janiis'));
const ManagerKigo = React.lazy(() => import('../pages/Manager/Integrations/Kigo'));
const ManagerLmpm = React.lazy(() => import('../pages/Manager/Integrations/Lmpm'));
const ManagerLodgify = React.lazy(() => import('../pages/Manager/Integrations/Lodgify'));
const ManagerLodgix = React.lazy(() => import('../pages/Manager/Integrations/Lodgix'));
const ManagerOneRooftop = React.lazy(() => import('../pages/Manager/Integrations/OneRooftop'));
const ManagerSkyRun = React.lazy(() => import('../pages/Manager/Integrations/SkyRun'));
const ManagerSmartBnb = React.lazy(() => import('../pages/Manager/Integrations/SmartBnb'));
const ManagerSmoobu = React.lazy(() => import('../pages/Manager/Integrations/Smoobu'));
const ManagerTokeet = React.lazy(() => import('../pages/Manager/Integrations/Tokeet'));
const ManagerTrack = React.lazy(() => import('../pages/Manager/Integrations/Track'));
const ManagerTripAdvisor = React.lazy(() => import('../pages/Manager/Integrations/TripAdvisor'));
const ManagerUplisting = React.lazy(() => import('../pages/Manager/Integrations/Uplisting'));
const ManagerVacationRentalDesk = React.lazy(() => import('../pages/Manager/Integrations/VacationRentalDesk'));
const ManagerVacationRentPro = React.lazy(() => import('../pages/Manager/Integrations/VacationRentPro'));
const ManagerWpHotel = React.lazy(() => import('../pages/Manager/Integrations/WpHotel'));
const ManagerYourPorter = React.lazy(() => import('../pages/Manager/Integrations/YourPorter'));
const ManagerPmbo = React.lazy(() => import('../pages/Manager/Integrations/Pmbo'));
const ManagerMyVr = React.lazy(() => import('../pages/Manager/Integrations/MyVr'));
const ManagerStreamline = React.lazy(() => import('../pages/Manager/Integrations/Streamline'));
const ManagerVrbo = React.lazy(() => import('../pages/Manager/Integrations/Vrbo'));
const ManagerVrm = React.lazy(() => import('../pages/Manager/Integrations/Vrm'));
const ManagerVrmSetup = React.lazy(() => import('../pages/Manager/Integrations/VrmSetup'));
const CleanerUploadScheduleByDay = React.lazy(() => import('../pages/CleanerAppointments/UploadScheduleByDay'));
const ManagerUploadScheduleByDay = React.lazy(() => import('../pages/ManagerAppointments/UploadScheduleByDay'));
const CleanerUploadedScheduleByDayList = React.lazy(() => import('../pages/CleanerAppointments/UploadedScheduleByDayList'));
const ManagerUploadedScheduleByDayList = React.lazy(() => import('../pages/ManagerAppointments/UploadedScheduleByDayList'));
const ManagerBrightSide = React.lazy(() => import('../pages/Manager/Integrations/BrightSide'));
const ManagerBrightSideSetup = React.lazy(() => import('../pages/Manager/Integrations/BrightSideSetup'));
const ManagerBookingAuto = React.lazy(() => import('../pages/Manager/Integrations/BookingAuto'));
const ManagerBookingAutoSetup = React.lazy(() => import('../pages/Manager/Integrations/BookingAutoSetup'));
const ManagerBookingAutoDisconnect = React.lazy(() => import('../pages/Manager/Integrations/BookingAutoDisconnect'));
const ManagerOwnerRez = React.lazy(() => import('../pages/Manager/Integrations/OwnerRez'));
const ManagerStreamlineDirect = React.lazy(() => import('../pages/Manager/Integrations/StreamlineDirect'));
const ManagerOwnerRezSetup = React.lazy(() => import('../pages/Manager/Integrations/OwnerRezSetup'));
const ManagerStreamlineDirectSetup = React.lazy(() => import('../pages/Manager/Integrations/StreamlineDirectSetup'));
const ManagerGuestyDirect = React.lazy(() => import('../pages/Manager/Integrations/GuestyDirect'));
const ManagerGuestyDirectSetup = React.lazy(() => import('../pages/Manager/Integrations/GuestyDirectSetup'));
const ManagerLiverezDirectSetup = React.lazy(() => import('../pages/Manager/Integrations/LiverezDirectSetup'));
const ManagerLiverezDirect = React.lazy(() => import('../pages/Manager/Integrations/LiverezDirect'));
const ManagerTrackDirect = React.lazy(() => import('../pages/Manager/Integrations/TrackDirect'));
const ManagerTrackDirectSetup = React.lazy(() => import('../pages/Manager/Integrations/TrackDirectSetup'));
const ManagerItrip = React.lazy(() => import('../pages/Manager/Integrations/Itrip'));
const ManagerItripSetup = React.lazy(() => import('../pages/Manager/Integrations/ItripSetup'));
const ManagerWebrezPro = React.lazy(() => import('../pages/Manager/Integrations/WebrezPro'));
const ManagerWebrezProSetup = React.lazy(() => import('../pages/Manager/Integrations/WebrezProDirectSetup'));
const CleanerSubTasks = React.lazy(() => import('../pages/Cleaner/SubTasks'));
const CleanerReportedIssues = React.lazy(() => import('../pages/Cleaner/ReportedIssues'));
const ManagerReportedIssues = React.lazy(() => import('../pages/Manager/ReportedIssues'));
const ManagerSubTasks = React.lazy(() => import('../pages/Manager/SubTasks'));
const CleanerWallet = React.lazy(() => import('../pages/Cleaner/Wallet'));
const ManagerWallet = React.lazy(() => import('../pages/Manager/Wallet'));
const CleanerTilledConnect = React.lazy(() => import('../pages/Cleaner/TilledConnect'));
const CleanerStripeConnect = React.lazy(() => import('../pages/Cleaner/StripeConnect'));
const CleanerInventoryReport = React.lazy(() => import('../pages/Cleaner/InventoryReport'));
const CleanerPropertyInventoryReport = React.lazy(() => import('../pages/Cleaner/PropertyInventoryReport'));
const ManagerInventoryReport = React.lazy(() => import('../pages/Manager/InventoryReport'));
const ManagerPropertyInventoryReport = React.lazy(() => import('../pages/Manager/PropertyInventoryReport'));
const ManagerOscarSetup = React.lazy(() => import('../pages/Manager/Integrations/OscarSetup'));
const ManagerOscar = React.lazy(() => import('../pages/Manager/Integrations/Oscar'));
const CleanerCreatePayroll = React.lazy(() => import('../pages/Cleaner/CreatePayroll'));
const ManagerCreatePayroll = React.lazy(() => import('../pages/Manager/CreatePayroll'));
const CleanerCreateInvoices = React.lazy(() => import('../pages/Cleaner/CreateInvoices'));
const CleanerViewInvoices = React.lazy(() => import('../pages/Cleaner/ViewInvoices'));
const ManagerViewInvoices = React.lazy(() => import('../pages/Manager/ViewInvoices'));
const CleanerViewPayroll = React.lazy(() => import('../pages/Cleaner/ViewPayroll'));
const ManagerViewPayroll = React.lazy(() => import('../pages/Manager/ViewPayroll'));
const CleanerEditInvoices = React.lazy(() => import('../pages/Cleaner/EditInvoices'));
const ManagerEditInvoices = React.lazy(() => import('../pages/Manager/EditInvoices'));
const CleanerScheduleStaff = React.lazy(() => import('../pages/Cleaner/ScheduleStaff'));
const ManagerScheduleStaff = React.lazy(() => import('../pages/Manager/ScheduleStaff'));
const ManagerWoCreateInvoice = React.lazy(() => import('../pages/Manager/WoCreateInvoice'));
const ManagerWoViewInvoice = React.lazy(() => import('../pages/Manager/WoViewInvoice'));
const ManagerWoEditInvoice = React.lazy(() => import('../pages/Manager/EditWoInvoice'));
const ManagerEscapia = React.lazy(() => import('../pages/Manager/Integrations/Escapia'));
const ManagerEscapiaSetup = React.lazy(() => import('../pages/Manager/Integrations/EscapiaSetup'));
const ManagerV12 = React.lazy(() => import('../pages/Manager/Integrations/V12'));
const ManagerV12Setup = React.lazy(() => import('../pages/Manager/Integrations/V12Setup'));
const AdminCreateInvoice = React.lazy(() => import('../pages/AdminPages/CreateInvoice'));
const AdminViewInvoice = React.lazy(() => import('../pages/AdminPages/ViewInvoice'));
const CleanerRcInvoices = React.lazy(() => import('../pages/Cleaner/RcInvoices'));
const ManagerRcInvoices = React.lazy(() => import('../pages/Manager/RcInvoices'));
const CleanerQuickbook = React.lazy(() => import('../pages/Cleaner/Quickbooks'));
const CleanerStatusCenter = React.lazy(() => import('../pages/Cleaner/StatusCenter'));
const ManagerStatusCenter = React.lazy(() => import('../pages/Manager/StatusCenter'));
const ManagerAvantio = React.lazy(() => import('../pages/Manager/Integrations/Avantio'));
const CleanerDashboardPage = React.lazy(() => import('../pages/Cleaner/Dashboard'));
const ManagerDashboardPage = React.lazy(() => import('../pages/Manager/Dashboard'));
const CleanerScheduleAppointmentRecurringServices = React.lazy(() => import('../pages/CleanerAppointments/ScheduleAppointmentRecurringServices'));
const ManagerScheduleAppointmentRecurringServices = React.lazy(() => import('../pages/ManagerAppointments/ScheduleAppointmentRecurringServices'));
const ManagerScheduleInternalRecurringServices = React.lazy(() => import('../pages/ManagerAppointments/ScheduleInternalRecurringServices'));
const CleanerEvolve = React.lazy(() => import('../pages/Cleaner/Integrations/Evolve'));
const CleanerVacasa = React.lazy(() => import('../pages/Cleaner/Integrations/Vacasa'));
const CleanerNewInventoryReport = React.lazy(() => import('../pages/Cleaner/InventoryNewReport'));
const ManagerNewInventoryReport = React.lazy(() => import('../pages/Manager/InventoryNewReport'));

const Wizard = React.lazy(() => import('../pages/Wizard'));
const WizardContent = React.lazy(() => import('../pages/WizardContent'));
const EmailTemplates = React.lazy(() => import('../pages/AdminPages/EmailTemplates'));
const Messages = React.lazy(() => import('../pages/AdminPages/Messages'));
const NotificationTemplats = React.lazy(() => import('../pages/AdminPages/NotificationTemplats'));
const CleanerMessages = React.lazy(() => import('../pages/Cleaner/Messages'));
const ManagerMessages = React.lazy(() => import('../pages/Manager/Messages'));
const ManagerItripWorkorder = React.lazy(() => import('../pages/Manager/Integrations/ItripWorkorder'));
const CleanerItripWorkorder = React.lazy(() => import('../pages/Cleaner/Integrations/ItripWorkorder'));
const CleanerITripWorkorderIntegration = React.lazy(() => import('../pages/Cleaner/Integrations/ITripWorkorderIntegration'));
const ManagerITripWorkorderIntegration = React.lazy(() => import('../pages/Manager/Integrations/ITripWorkorderIntegration'));

const CleanerInventorySmartFormPage = React.lazy(() => import('../pages/Cleaner/Integrations/InventorySmartFormPage'));
const CleanerInventorySmartListPage = React.lazy(() => import('../pages/Cleaner/Integrations/InventorySmartListPage'));
const ManagerInventorySmartFormPage = React.lazy(() => import('../pages/Manager/Integrations/InventorySmartFormPage'));
const ManagerInventorySmartListPage = React.lazy(() => import('../pages/Manager/Integrations/InventorySmartListPage'));
const ManagerInventorySmartRequests = React.lazy(() => import('../pages/Manager/InventorySmartRequest'))
const BarefootIntegrationListPage = React.lazy(() => import('../pages/Manager/Integrations/BarefootIntegrationListPage'));
const BarefootIntegrationFormPage = React.lazy(() => import('../pages/Manager/Integrations/BarefootIntegrationFormPage'));
const AdminPropertyPricing = React.lazy(() => import('../pages/AdminPages/AdminPropertyPricing'));
const CleanerProductivityReport = React.lazy(() => import('../pages/Cleaner/ProductivityReport'));
const CleanerInspectorProductivityReport = React.lazy(() => import('../pages/Cleaner/InspectorProductivityReport'));
const ManagerChangePassword = React.lazy(() => import('../pages/Manager/ChangePassword'));
const CleanerChangePassword = React.lazy(() => import('../pages/Cleaner/ChangePassword'));
const LmpmIntegrationListPage = React.lazy(() => import('../pages/Manager/Integrations/LmpmIntegrationListPage'));
const VacationRentIntegrationListPage = React.lazy(() => import('../pages/Manager/Integrations/VacationRentIntegrationListPage'));
const UplistingIntegrationListPage = React.lazy(() => import('../pages/Manager/Integrations/UplistingIntegrationListPage'));
const LodgifyIntegrationListPage = React.lazy(() => import('../pages/Manager/Integrations/LodgifyIntegrationListPage'));
const ManagerLodgifyFormPage = React.lazy(() => import('../pages/Manager/Integrations/LodgifyDirectFormPage'));

const ClientPayment = React.lazy(() => import('../pages/EmailPayment'));
const RcEmailInvoicePayment = React.lazy(() => import('../pages/RcEmailInvoicePayment'));

const ReaderPage = React.lazy(() => import('../pages/Reader/ReaderPage'));
const ReaderSearchPage = React.lazy(() => import('../pages/Reader/ReaderSearchPage'));
const ViewReaderPage = React.lazy(() => import('../pages/Reader/ViewReaderPage'));
const CleanerFailedInspectionItemsReports = React.lazy(() => import('../pages/Cleaner/FailedInspectionItemsReports'));
const CleanerCompletedTimesReport = React.lazy(() => import('../pages/Cleaner/CompletedTimesReport'));

const CleanerTimesheetReport = React.lazy(() => import('../pages/Cleaner/TimesheetReport'));
const ManagerTimesheetReport = React.lazy(() => import('../pages/Manager/TimesheetReport'));
const ManagerLmpmFormPage = React.lazy(() => import('../pages/Manager/Integrations/LmpmFormPage'));
const CleanerImportPropertyPrice = React.lazy(() => import ( '../pages/Cleaner/ImportPropertyPrice'));
const CleanerUploadImportPropertyPrice = React.lazy(() => import ( '../pages/Cleaner/UploadImportPropertyPrice'));
const ManagerVacationRentFormPage = React.lazy(() => import('../pages/Manager/Integrations/VacationRentFormPage'));
const ManagerUplistingFormPage = React.lazy(() => import('../pages/Manager/Integrations/UplistingDirectFormPage'));

const ManagerSojoIntegration = React.lazy(() => import ( '../pages/Manager/Integrations/SojoIntegration'));
const ManagerSojoConfiguration = React.lazy(() => import ( '../pages/Manager/Integrations/SojoConfiguration'));
const CleanerSojoIntegration = React.lazy(() => import ( '../pages/Cleaner/Integrations/SojoIntegration'));
const CleanerSojoConfiguration = React.lazy(() => import ( '../pages/Cleaner/Integrations/SojoConfiguration'))
const CleanerDailyCloseoutReport = React.lazy(() => import ( '../pages/Cleaner/DailyCloseoutReport'))
const CleanerBoardAssignment = React.lazy(() => import ( '../pages/Cleaner/BoardAssignment'))
const CleanerAssignCollectionMethod = React.lazy(() => import ( '../pages/Cleaner/AssignCollectionMethod'))
const CleanerRegionAndAreaConfig = React.lazy(() => import ( '../pages/Cleaner/RegionAndAreaConfig'));
const ManagerRegionAndAreaConfig = React.lazy(() => import ( '../pages/Manager/RegionAndAreaConfig'));
const SalesforceMapping = React.lazy(() => import ( '../pages/SalesforceIntegration/PropertyMapping'));
const SalesforceNewProperties = React.lazy(() => import ( '../pages/SalesforceIntegration/NewProperties'));

const CleanerServiceMapping = React.lazy(() => import ( '../pages/Cleaner/Quickbooks/ServiceMapping'));
const CleanerPropertyManagerMapping = React.lazy(() => import ( '../pages/Cleaner/Quickbooks/PropertyManagerMapping'));
const CleanerStaffMapping = React.lazy(() => import ( '../pages/Cleaner/Quickbooks/StaffMapping'));
const AdminViewAppointmentsPage = React.lazy(() => import ( '../pages/AdminPages/AdminViewAppointments'));

const  CleanerCommonAreasListPage = React.lazy(() => import ( '../pages/Cleaner/CommonAreas/CommonAreasListPage'));
const  CleanerCommonAreasTemplateListPage = React.lazy(() => import ( '../pages/Cleaner/CommonAreas/CommonAreasTemplateListPage'));
const  CleanerCommonAreaUploadTemplatePage = React.lazy(() => import ( '../pages/Cleaner/CommonAreas/CommonAreaUploadTemplatePage'));
const  CleanerEditCommonAreaTemplatePage= React.lazy(() => import ( '../pages/Cleaner/CommonAreas/EditCommonAreaTemplatePage'));
const  CleanerAddCommonAreaPage = React.lazy(() => import ( '../pages/Cleaner/CommonAreas/AddCommonAreaPage'));


const CleanerCommonAreasAppointmentsPage = React.lazy(() => import ('../pages/Cleaner/CommonAreasAppointments/CommonAreasAppointmentsPage'));
const CleanerCommonAreasScheduleAppointmentsPage = React.lazy(() => import ('../pages/Cleaner/CommonAreasAppointments/CommonAreasScheduleAppointmentsPage'));
const CleanerCommonAreasViewEditAppointmentsPage = React.lazy(() => import ('../pages/Cleaner/CommonAreasAppointments/CommonAreasViewEditAppointmentsPage'));
const CleanerWoItemMapping =React.lazy(()=>import('../pages/Cleaner/Quickbooks/WoItemMapping'))
const AdminBroadcastMessageLisPage = React.lazy(() => import ( '../pages/AdminPages/BroadcastMessageList'));
const CleanerHHSServiceTrackerReport = React.lazy(() => import ( '../pages/Cleaner/HHS/HHSServiceTrackerReport'));
const AdminHHSServiceTrackerReport = React.lazy(() => import ( '../pages/AdminPages/HHSReports/HHSServiceTrackerReport'));

const CleanerHHSEmployeePieceReport = React.lazy(() => import ( '../pages/Cleaner/HHS/HHSEmployeePieceReport'));
const CleanerHHSEmployeePieceRateReport = React.lazy(() => import ( '../pages/Cleaner/HHS/HHSEmployeePieceRateReport'));
const AdminHHSEmployeePieceReport = React.lazy(() => import ( '../pages/AdminPages/HHSReports/HHSEmployeePieceReport'));
const AdminHHSEmployeePieceRateReport = React.lazy(() => import ( '../pages/AdminPages/HHSReports/HHSEmployeePieceRateReport'));

const UnsubscribePage = React.lazy(() => import('../pages/UnsubscribePage'));
const ErrorPage = React.lazy(() => import('../pages/Erorr/ErrorPage'));
const SessionError = React.lazy(() => import('../pages/Erorr/SessionOutError'));
const AdminDashboardPage = React.lazy(() => import('../pages/AdminPages/AdminDashboard'));
const CleanerUnitStatusView = React.lazy(() => import('../pages/Cleaner/UnitStatusView'));
const ManagerUnitStatusView = React.lazy(() => import('../pages/Manager/UnitStatusView'));

const AdminHHSWeeklyInvoiceReport = React.lazy(()=>import('../pages/AdminPages/HHS/HHSWeeklyInvoiceReport'))

const CleanerHHSWeeklyInvoiceReport = React.lazy(() => import ('../pages/Cleaner/HHS/HHSWeeklyInvoiceReport'));
const ManagerVillas365 = React.lazy(() => import('../pages/Manager/Integrations/Villas365'));
const ManagerCirrus = React.lazy(() => import('../pages/Manager/Integrations/Cirrus'));
const CleanerCardpointeConnect = React.lazy(() => import('../pages/Cleaner/CardpointConnect'));
const Managerbeds24 = React.lazy(() => import('../pages/Manager/Integrations/Beds24'));
const OutsideServices = React.lazy(() => import('../pages/Manager/OutsideServices'));
const CleanerAnalytics = React.lazy(() => import('../pages/Cleaner/Analytics'));
const ManagerAnalytics = React.lazy(() => import('../pages/Manager/Analytics'));
const HostfullyDirectIntegrationListPage = React.lazy(() => import('../pages/Manager/Integrations/HostfullyDirectIntegrationListPage'));

const ManagerHostfullyDirectFormPage = React.lazy(() => import('../pages/Manager/Integrations/HostfullyDirectFormPage'));

const ManagerRmsCloudSetup = React.lazy(() => import('../pages/Manager/Integrations/RmsCloudSetup'));
const ManagerRmsCloud = React.lazy(() => import('../pages/Manager/Integrations/RmsCloud'));

const ManagerHospitableSetup = React.lazy(() => import('../pages/Manager/Integrations/HospitableSetup'));
const ManagerHospitableListPage = React.lazy(() => import('../pages/Manager/Integrations/HospitableListPage'));

const ManagerStayCloud = React.lazy(() => import('../pages/Manager/Integrations/StayCloud'));
const ManagerStayCloudSetup = React.lazy(() => import('../pages/Manager/Integrations/StayCloudSetup'));

const CleanerAirbnbIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Airbnb'));
const CleanerAlbamaVhrIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/AlbamaVhr'));
const CleanerBeds24Integration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Beds24'));
const CleanerBookingComIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/BookingCom'));
const CleanerBookingsyncIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/BookingSync'));
const CleanerCirrusIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Cirrus'));
const CleanerDirectpmsIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/DirectPms'));
const CleanerGuestyIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Guesty'));
const CleanerYourporterIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Yourporter'));
const CleanerHostawayIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Hostaway'));
const CleanerHostfullyIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Hostfully'));
const CleanerHostifyIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Hostify'));
const CleanerHoufyIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Houfy'));
const CleanerIgmsIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Igms'));
const CleanerIvacationIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Ivacation'));
const CleanerJaniisIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Janiis'));
const CleanerKigoIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Kigo'));
const CleanerLodgifyIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Lodgify'));
const CleanerLodgixIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Lodgix'));
const CleanerSmoobuIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Smoobu'));
const CleanerTokeetIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Tokeet'));
const CleanerTrackIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Track'));
const CleanerUplistingIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Uplisting'));
const CleanerVacationRentalDeskIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/VacationRentalDesk'));
const CleanerVacationRentProIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/VacationRentPro'));
const CleanerWpHotelIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/WpHotel'));
const CleanerStreamlineIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Streamline'));
const CleanerVillas365Integration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Villas365'));
const CleanerEscapiaIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Escapia'));
const CleanerOneRooftopIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/OneRooftop'));
const CleanerSkyRunIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/SkyRun'));
const CleanerSmartBnbIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/SmartBnb'));
const CleanerTrackDirectIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/TrackDirect'));
const CleanerTripAdvisorIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/TripAdvisor'));
const CleanerVrbo = React.lazy(() => import('../pages/Cleaner/Integrations/Vrbo'));
const CleanerPmboIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/Pmbo'));
const CleanerLivrez = React.lazy(() =>  import('../pages/Cleaner/Integrations/Livrez'));
const CleanerRezkey = React.lazy(() =>  import('../pages/Cleaner/Integrations/Rezkey'));
const CleanerMyVr = React.lazy(() =>  import('../pages/Cleaner/Integrations/MyVr'));
const CleanerHospitable = React.lazy(() =>  import('../pages/Cleaner/Integrations/Hospitable'));
const CleanerWorkorderDepartment= React.lazy(() =>  import('../pages/Cleaner/WorkorderDepartment'));
const ManagerBrightsideWorkorder = React.lazy(() =>  import('../pages/Manager/Integrations/BrightsideWorkorderConfiguration'));
const CleanerPropertyManagerConfig = React.lazy(() =>  import('../pages/Cleaner/PropertyManagerConfig'));

const CleanerSuperControlIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/SuperControl'));
const ManagerSuperControl = React.lazy(() => import('../pages/Manager/Integrations/SuperControl'));
const AdminChurnReport = React.lazy(() => import('../pages/AdminPages/AdminChurnReport'));
const CleanerEditPayrolls = React.lazy(() => import('../pages/Cleaner/EditPayrolls'));
const ManagerEditPayrolls = React.lazy(() => import('../pages/Manager/EditPayrolls'));
const CleanerPropertyManagerInventoryReport = React.lazy(() => import('../pages/Cleaner/PropertyManagerInventoryReport'));
const CleanerStaffPlanner = React.lazy(() => import('../pages/Cleaner/StaffPlanner'));

const CleanerInventoryTemplate = React.lazy(() => import('../pages/Cleaner/InventoryTemplate'));
const ManagerInventoryTemplate = React.lazy(() => import('../pages/Manager/InventoryTemplate'));

const CleanerPropertyNewInventoryReport = React.lazy(() => import('../pages/Cleaner/PropertyNewInventoryReport'));
const ManagerPropertyNewInventoryReport = React.lazy(() => import('../pages/Manager/PropertyNewInventoryReport'));

const CleanerAlluraDirectIntegration = React.lazy(() =>  import('../pages/Cleaner/Integrations/AlluraDirect'));
const ManagerAlluraDirectIntegration = React.lazy(() => import('../pages/Manager/Integrations/AlluraDirect'));
const CleanerUpdateRecurringAppointment = React.lazy(() => import('../pages/CleanerAppointments/updateRecurringAppoitmentPages'));
const ManagerUpdateRecurringAppointment = React.lazy(() => import('../pages/ManagerAppointments/updateRecurringAppoitmentPages'));

const CleanerSmsInbox = React.lazy(() => import("../pages/Cleaner/SmsInbox"));
const ManagerSmsInbox = React.lazy(() => import("../pages/Manager/SmsInbox"));

const CleanerCompanyGeoLocations = React.lazy(() => import("../pages/Cleaner/CompanyGeoLocations"));

const ManagerAlluraDirectFormPage = React.lazy(() => import('../pages/Manager/Integrations/AlluraDirectFormPage'));
const AlluraDirectIntegrationListPage = React.lazy(() => import('../pages/Manager/Integrations/AlluraDirectIntegrationListPage'));
const CleanerHHSWeeklyInvoiceNewReport = React.lazy(() => import ('../pages/Cleaner/HHS/HHSWeeklyInvoiceNewReport'));
const AdminHHSWeeklyInvoiceNewReport = React.lazy(()=>import('../pages/AdminPages/HHS/HHSWeeklyInvoiceNewReport'))

const AdminPropertyMap = React.lazy(() => import('../pages/AdminPages/AdminPropertyMap'));
const AppointmentPreviewPage = React.lazy(() => import('../pages/PublicPages/AppointmentPreviewPage'));

const CleanerPayrollReport = React.lazy(() => import('../pages/Cleaner/PayrollReport'));
const ManagerPayrollReport = React.lazy(() => import('../pages/Manager/PayrollReport'));
const CleanerDirectory = React.lazy(() => import('../pages/Manager/CleanerDirectory'));
const AdminCleanerProfileReview = React.lazy(() => import('../pages/AdminPages/AdminCleanerProfileReview'));
const ManagerQuoteRequestsPage = React.lazy(() => import('../pages/Manager/QuoteRequests'));
const CleanerQuoteRequestsPage = React.lazy(() => import('../pages/Cleaner/QuoteRequests'));

export const AppRouter = () => {
    const { keycloak, initialized } = useKeycloak();
    if (!initialized) {
        return <h3>Loading ... !!!</h3>;
    }
    
    return (<>
        <BrowserRouter>
            <Suspense fallback={<Loader isLoading={true}/>}>
            <Switch>

                <PrivateRoute path="/admin" component={AdminUsers} allowedRoles={["SA", "AD"]} />
                <PrivateRoute path="/cleaners" component={Cleaners} allowedRoles={["SA", "AD"]} />
                <PrivateRoute path="/property-managers" component={PropertyManagers} allowedRoles={["SA", "AD"]} />
                <PrivateRoute path="/configuration" component={Configuration} allowedRoles={["SA", "AD"]} />
                <PrivateRoute path="/admin-create-invoice" component={AdminCreateInvoice} allowedRoles={["SA", "AD"]} />
                <PrivateRoute path="/admin-view-invoice" component={AdminViewInvoice} allowedRoles={["SA", "AD"]} />
                <PrivateRoute path="/admin-email-templates" component={EmailTemplates} allowedRoles={["SA", "AD"]} />
                <PrivateRoute path="/admin-notification-templates" component={NotificationTemplats} allowedRoles={["SA", "AD"]} />
                <PrivateRoute path="/admin-messages" component={Messages} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-property-pricing" component={AdminPropertyPricing} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/reader" component={ReaderPage} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/reader-search/:integration" component={ReaderSearchPage} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/view-reader" component={ViewReaderPage} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-my-account" component={AdminMyAccount} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-change-password" component={AdminChangePassword} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-dashboard" component={AdminDashboardPage} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-hhs-weekly-invoice-report" component={AdminHHSWeeklyInvoiceReport} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-hhs-employee-piece-report" component={AdminHHSEmployeePieceReport} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-hhs-employee-piece-rate-report" component={AdminHHSEmployeePieceRateReport} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-hhs-service-tracker-report" component={AdminHHSServiceTrackerReport} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-view-appointments" component={AdminViewAppointmentsPage} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-broadcast-messages" component={AdminBroadcastMessageLisPage} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-churn-report" component={AdminChurnReport} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-property-map" component={AdminPropertyMap} allowedRoles={["SA", "AD"]}/>
                <PrivateRoute path="/admin-cleaner-profile-review" component={AdminCleanerProfileReview} allowedRoles={["SA", "AD"]}/>

                <PrivateRoute path="/wizard" component={Wizard} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/wizardcontent" component={WizardContent} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/staff-management" component={StaffManagement} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-region" component={CleanerRegion} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-area" component={CleanerArea} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/services" component={ServicePage} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/services-automation" component={ServiceAutomationPage} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-inventory" component={CleanerInventory} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/client-list" component={CleanerClieantList} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/hkgroups" component={HkGroups} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/profile" component={CleanerProfile} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-properties" component={CleanerProperties} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/branding" component={Branding} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-my-account" component={CleanerMyAccount} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-staff-account" component={CleanerStaffMyAccount} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-price-config" component={CleanerPriceConfig} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-service-config" component={CleanerServiceConfig} allowedRoles={["CL", "ST"]} />

                <PrivateRoute path="/cleaner-appointments" component={CleanerAppointments} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-edit-appointments" component={CleanerEditAppointments} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/schedule-appointments" component={ScheduleAppointments} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/upload-property/:id" component={UploadProperties} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-inspection-template" component={CleanerInspectionTemplate} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-inspections" component={CleanerInspections} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-property-inspection" component={CleanerPropertyInspection} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-facility-inspection" component={CleanerFacilityInspection} allowedRoles={["CL", "ST"]} />

                <PrivateRoute path="/cleaner-service-checklist" component={CleanerServiceChecklist} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-work-order-items" component={CleanerWorkOrderItems} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-schedule-single-day" component={ScheduleSingleDay} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-work-orders" component={CleanerWorkOrders} allowedRoles={["CL", "ST"]} />

                <PrivateRoute path="/cleaner-upload-schedule" component={CleanerUploadSchedule} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-uploaded-schedule-list/:id" component={CleanerUploadedScheduleList} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-assign-staff-list" component={CleanerAssignStaff} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-cancel-appointments" component={CleanerCancelAppointments} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-support-tasks" component={CleanerSupportTasks} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-service-request" component={CleanerServiceRequest} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-appointments-tasks" component={CleanerAppointmentsTasks} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-subtasks" component={CleanerSubTasks} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-reported-issues" component={CleanerReportedIssues} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-wallet" component={CleanerWallet} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-tilled-connect" component={CleanerTilledConnect} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-stripe-connect" component={CleanerStripeConnect} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-inventory-report" component={CleanerInventoryReport} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-property-inventory-report" component={CleanerPropertyInventoryReport} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-create-payroll" component={CleanerCreatePayroll} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-create-invoices" component={CleanerCreateInvoices} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-view-invoices" component={CleanerViewInvoices} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-view-payroll" component={CleanerViewPayroll} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-edit-invoices" component={CleanerEditInvoices} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-schedule-staff" component={CleanerScheduleStaff} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-rc-invoices" component={CleanerRcInvoices} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-quickbook" component={CleanerQuickbook} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-status-center" component={CleanerStatusCenter} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-dashboard" component={CleanerDashboardPage} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-schedule-recurring" component={CleanerScheduleAppointmentRecurringServices} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-evolve" component={CleanerEvolve} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-vacasa" component={CleanerVacasa} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-messages" component={CleanerMessages} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-itrip-workorder" component={CleanerItripWorkorder} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-itrip-workorder-integration" component={CleanerITripWorkorderIntegration} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-inventory-smart-form-page" component={CleanerInventorySmartFormPage} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-inventory-smart-list-page" component={CleanerInventorySmartListPage} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-productivity-report" component={CleanerProductivityReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-inspector-productivity-report" component={CleanerInspectorProductivityReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-change-password" component={CleanerChangePassword} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-failed-inspection-items-reports" component={CleanerFailedInspectionItemsReports} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-completed-times-reports" component={CleanerCompletedTimesReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-timesheet-report" component={CleanerTimesheetReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-upload-evolve" component={CleanerUploadEvolve} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-import-property-price" component={CleanerImportPropertyPrice} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-upload-import-property-price-list/:id" component={CleanerUploadImportPropertyPrice} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-sojo-integration" component={CleanerSojoIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-sojo-config" component={CleanerSojoConfiguration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-daily-closeout-report" component={CleanerDailyCloseoutReport} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-jobs-and-revenue-report" component={CleanerJobsAndRevenueReport} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-board-assignment" component={CleanerBoardAssignment} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-assign-collection-method" component={CleanerAssignCollectionMethod} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-region-area-config" component={CleanerRegionAndAreaConfig} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-service-mapping" component={CleanerServiceMapping} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-property-manager-mapping" component={CleanerPropertyManagerMapping} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-staff-mapping" component={CleanerStaffMapping} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-common-areas-list" component={CleanerCommonAreasListPage} allowedRoles={["CL", "ST"]}/>
            <PrivateRoute path="/cleaner-common-areas-template" component={CleanerCommonAreasTemplateListPage} allowedRoles={["CL", "ST"]}/>
            <PrivateRoute path="/cleaner-common-area-upload-template" component={CleanerCommonAreaUploadTemplatePage} allowedRoles={["CL", "ST"]}/>
            <PrivateRoute path="/cleaner-edit-common-area-template/:id" component={CleanerEditCommonAreaTemplatePage} allowedRoles={["CL", "ST"]}/>
            <PrivateRoute path="/cleaner-add-common-area" component={CleanerAddCommonAreaPage} allowedRoles={["CL", "ST"]}/>

                <PrivateRoute path="/cleaner-common-areas-appointments" component={CleanerCommonAreasAppointmentsPage} allowedRoles={["CL", "ST"]}/>
            <PrivateRoute path="/cleaner-common-areas-schedule-appointments" component={CleanerCommonAreasScheduleAppointmentsPage} allowedRoles={["CL", "ST"]}/>
            <PrivateRoute path="/cleaner-common-areas-view-edit-appointments/:id" component={CleanerCommonAreasViewEditAppointmentsPage} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-unit-status-view" component={CleanerUnitStatusView} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-workorder-item-mapping" component={CleanerWoItemMapping} allowedRoles={["CL", "ST"]}/>
                

                <PrivateRoute path="/cleaner-hhs-service-tracker-report" component={CleanerHHSServiceTrackerReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-hhs-weekly-invoice-report" component={CleanerHHSWeeklyInvoiceReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-hhs-employee-piece-report" component={CleanerHHSEmployeePieceReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-hhs-employee-piece-rate-report" component={CleanerHHSEmployeePieceRateReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-analytics" component={CleanerAnalytics} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-property-manager-config" component={CleanerPropertyManagerConfig} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-staff-planner" component={CleanerStaffPlanner} allowedRoles={["CL", "ST"]}/>

                <PrivateRoute path="/cleaner-new-inventory-report" component={CleanerNewInventoryReport} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/manager-new-inventory-report" component={ManagerNewInventoryReport} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-analytics" component={ManagerAnalytics} allowedRoles={["MN", "ST"]}/>

                <PrivateRoute path="/manager-staff-management" component={ManagerStaffManagement} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-area" component={ManagerArea} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-region" component={ManagerRegion} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-cleaner-list" component={ManagerCleaners} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-inventory" component={ManagerInventory} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-properties" component={ManagerProperties} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-my-account" component={ManagerMyAccount} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-staff-account" component={ManagerStaffMyAccount} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-price-config" component={ManagerPriceConfig} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-service-config" component={ManagerServiceConfig} allowedRoles={["MN", "ST"]} />
                
                <PrivateRoute path="/manager-property-messaging" component={ManagerPropertyMessagingConfig} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/cleaner-property-messaging" component={CleanerPropertyMessagingConfig} allowedRoles={["CL", "ST"]} />
                
                <PrivateRoute path="/manager-appointment-messaging" component={ManagerAppointmentMessaging} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/cleaner-appointment-messaging" component={CleanerAppointmentMessaging} allowedRoles={["CL", "ST"]} />

                <PrivateRoute path="/manager-appointments" component={ManagerAppointments} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-edit-appointments" component={ManagerEditAppointments} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-schedule-appointments" component={ManagerScheduleAppointment} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-upload-property/:id" component={ManagerUploadProperties} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-inspection-template" component={ManagerInspectionTemplate} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-inspections" component={ManagerInspections} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-property-inspection" component={ManagerPropertyInspection} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-facility-inspection" component={ManagerFacilityInspection} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-service-checklist" component={ManagerServiceChecklist} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-work-order-items" component={ManagerWorkOrderItems} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-schedule-single-day" component={ManagerScheduleSingleDay} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-internal-services" component={ManagerInternalServices} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-services-automation" component={ManagerServicesAutomation} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-schedule-internal-appointments" component={ManagerScheduleInternalAppointments} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-work-orders" component={ManagerWorkOrders} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-upload-schedule" component={ManagerUploadSchedule} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-uploaded-schedule-list/:id" component={ManagerUploadedScheduleList} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-assign-staff-list" component={ManagerAssignStaff} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-cancel-appointments" component={ManagerCancelAppointments} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-ical-aribnb" component={ManagerAirbnb} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-ical-avhr" component={ManagerAlbamaVhr} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-ical-avantio" component={ManagerAvantio} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-ical-bookingsync" component={ManagerBookingSync} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-ical-directpms" component={ManagerDirectPms} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-bookingcom" component={ManagerBookingCom} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-livrez" component={ManagerLivrez} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-rezkey" component={ManagerRezkey} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-guesty" component={ManagerGuesty} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-hospitable" component={ManagerHospitable} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-hostaway" component={ManagerHostaway} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-hostawaydirectsetup" component={ManagerHostawayDirectSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-hostawaydirect" component={ManagerHostawayDirect} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-hostfully" component={ManagerHostfully} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-hostify" component={ManagerHostify} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-houfy" component={ManagerHoufy} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-igms" component={ManagerIgms} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-ivacation" component={ManagerIvacation} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-janiis" component={ManagerJaniis} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-kigo" component={ManagerKigo} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-lmpm" component={ManagerLmpm} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-lodgify" component={ManagerLodgify} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-lodgix" component={ManagerLodgix} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-onerooftop" component={ManagerOneRooftop} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-skyRun" component={ManagerSkyRun} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-smartBnb" component={ManagerSmartBnb} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-smoobu" component={ManagerSmoobu} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-tokeet" component={ManagerTokeet} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-track" component={ManagerTrack} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-tripadvisor" component={ManagerTripAdvisor} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-uplisting" component={ManagerUplisting} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-vacationrentaldesk" component={ManagerVacationRentalDesk} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-vacationrentpro" component={ManagerVacationRentPro} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-wphotel" component={ManagerWpHotel} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-yourporter" component={ManagerYourPorter} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-pmbo" component={ManagerPmbo} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-myvr" component={ManagerMyVr} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-streamline" component={ManagerStreamline} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-vrbo" component={ManagerVrbo} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-vrm" component={ManagerVrm} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-vrmsetup" component={ManagerVrmSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-brightside" component={ManagerBrightSide} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-brightsidesetup" component={ManagerBrightSideSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-bookingauto" component={ManagerBookingAuto} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-bookingautosetup" component={ManagerBookingAutoSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-bookingautodisconnect" component={ManagerBookingAutoDisconnect} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-ownerrez" component={ManagerOwnerRez} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-streamlinedirect" component={ManagerStreamlineDirect} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-streamlinedirectsetup" component={ManagerStreamlineDirectSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-ownerrezsetup" component={ManagerOwnerRezSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-guestydirect" component={ManagerGuestyDirect} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-guestydirectsetup" component={ManagerGuestyDirectSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-liverezdirect" component={ManagerLiverezDirect} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-liverezdirectsetup" component={ManagerLiverezDirectSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-trackdirect" component={ManagerTrackDirect} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-trackdirectsetup" component={ManagerTrackDirectSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-itrip" component={ManagerItrip} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-itripsetup" component={ManagerItripSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-webrezpro" component={ManagerWebrezPro} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-webrezprosetup" component={ManagerWebrezProSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-escapia" component={ManagerEscapia} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-escapiasetup" component={ManagerEscapiaSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-v12" component={ManagerV12} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-v12setup" component={ManagerV12Setup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-oscar" component={ManagerOscar} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-oscarsetup" component={ManagerOscarSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-service-request" component={ManagerServiceRequest} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/cleaner-upload-schedule-by-day" component={CleanerUploadScheduleByDay} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/manager-upload-schedule-by-day" component={ManagerUploadScheduleByDay} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/cleaner-uploaded-schedule-by-day-list/:id" component={CleanerUploadedScheduleByDayList} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/manager-uploaded-schedule-by-day-list/:id" component={ManagerUploadedScheduleByDayList} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-subtasks" component={ManagerSubTasks} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-reported-issues" component={ManagerReportedIssues} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-wallet" component={ManagerWallet} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-inventory-report" component={ManagerInventoryReport} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-property-inventory-report" component={ManagerPropertyInventoryReport} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-create-payroll" component={ManagerCreatePayroll} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-view-invoices" component={ManagerViewInvoices} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-view-payroll" component={ManagerViewPayroll} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-edit-invoices" component={ManagerEditInvoices} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-schedule-staff" component={ManagerScheduleStaff} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-wo-create-invoice" component={ManagerWoCreateInvoice} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-wo-view-invoice" component={ManagerWoViewInvoice} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-wo-edit-invoice" component={ManagerWoEditInvoice} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-rc-invoices" component={ManagerRcInvoices} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-status-center" component={ManagerStatusCenter} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-dashboard" component={ManagerDashboardPage} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-schedule-recurring" component={ManagerScheduleAppointmentRecurringServices} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-schedule-internal-recurring" component={ManagerScheduleInternalRecurringServices} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-messages" component={ManagerMessages} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-itrip-workorder" component={ManagerItripWorkorder} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-itrip-workorder-integration" component={ManagerITripWorkorderIntegration} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-inventory-smart-form-page" component={ManagerInventorySmartFormPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-inventory-smart-list-page" component={ManagerInventorySmartListPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-inventory-smart-requests" component={ManagerInventorySmartRequests} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-barefoot-form" component={BarefootIntegrationFormPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-barefoot-list" component={BarefootIntegrationListPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-change-password" component={ManagerChangePassword} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-timesheet-report" component={ManagerTimesheetReport} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-lmpm-integration" component={ManagerLmpmFormPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-lmpm-list" component={LmpmIntegrationListPage} allowedRoles={["MN", "ST"]}/>
                
                <PrivateRoute path="/manager-sojo-integration" component={ManagerSojoIntegration} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-sojo-config" component={ManagerSojoConfiguration} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-region-area-config" component={ManagerRegionAndAreaConfig} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-salesforce-property-mapping" component={SalesforceMapping} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-salesforce-new-properties" component={SalesforceNewProperties} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-vacation-rent-pro-integration" component={ManagerVacationRentFormPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-vacation-rent-pro-list" component={VacationRentIntegrationListPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-unit-status-view" component={ManagerUnitStatusView} allowedRoles={["MN", "ST"]}/>

                <PrivateRoute path="/manager-alt-ical-uplisting-integration" component={ManagerUplistingFormPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-uplisting-list" component={UplistingIntegrationListPage} allowedRoles={["MN", "ST"]}/>

                <PrivateRoute path="/manager-alt-ical-365villas" component={ManagerVillas365} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-cirrus" component={ManagerCirrus} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/outside-services" component={OutsideServices} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-beds24" component={Managerbeds24} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-hostfullydirect-integration" component={ManagerHostfullyDirectFormPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-hostfullydirect-list" component={HostfullyDirectIntegrationListPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/cleaner-cardpointe-connect" component={CleanerCardpointeConnect} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/manager-rmscloud-integration" component={ManagerRmsCloudSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-rmscloud-list" component={ManagerRmsCloud} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-hospitable-setup" component={ManagerHospitableSetup} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-hospitable-list" component={ManagerHospitableListPage} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-staycloud-integration" component={ManagerStayCloud} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-staycloudsetup" component={ManagerStayCloudSetup} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-alt-ical-supercontrol" component={ManagerSuperControl} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/cleaner-ical-aribnb" component={CleanerAirbnbIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-ical-avhr" component={CleanerAlbamaVhrIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-beds24" component={CleanerBeds24Integration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-bookingcom" component={CleanerBookingComIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-ical-bookingsync" component={CleanerBookingsyncIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-cirrus" component={CleanerCirrusIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-ical-directpms" component={CleanerDirectpmsIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-guesty" component={CleanerGuestyIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-yourporter" component={CleanerYourporterIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-hostaway" component={CleanerHostawayIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-hostfully" component={CleanerHostfullyIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-hostify" component={CleanerHostifyIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-houfy" component={CleanerHoufyIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-igms" component={CleanerIgmsIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-ivacation" component={CleanerIvacationIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-janiis" component={CleanerJaniisIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-kigo" component={CleanerKigoIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-lodgify" component={CleanerLodgifyIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-lodgix" component={CleanerLodgixIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-smoobu" component={CleanerSmoobuIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-tokeet" component={CleanerTokeetIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-track" component={CleanerTrackIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-uplisting" component={CleanerUplistingIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-vacationrentaldesk" component={CleanerVacationRentalDeskIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-vacationrentpro" component={CleanerVacationRentProIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-wphotel" component={CleanerWpHotelIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-365villas" component={CleanerVillas365Integration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-vrbo" component={CleanerVrbo} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-onerooftop" component={CleanerOneRooftopIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-skyRun" component={CleanerSkyRunIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-smartBnb" component={CleanerSmartBnbIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-tripadvisor" component={CleanerTripAdvisorIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-escapia" component={CleanerEscapiaIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-streamline" component={CleanerStreamlineIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-trackdirect" component={CleanerTrackDirectIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-pmbo" component={CleanerPmboIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-livrez" component={CleanerLivrez} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-rezkey" component={CleanerRezkey} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-alt-ical-myvr" component={CleanerMyVr} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/cleaner-alt-ical-hospitable" component={CleanerHospitable} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/cleaner-workorder-department" component={CleanerWorkorderDepartment} allowedRoles={["CL", "ST"]} />

                <PrivateRoute path="/cleaner-alt-ical-supercontrol" component={CleanerSuperControlIntegration} allowedRoles={["CL", "ST"]} />

                <PrivateRoute path="/manager-brightside-workorders-config" component={ManagerBrightsideWorkorder} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/cleaner-edit-payrolls" component={CleanerEditPayrolls} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/manager-edit-payrolls" component={ManagerEditPayrolls} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/Cleaner-property-manager-inventory-report" component={CleanerPropertyManagerInventoryReport} allowedRoles={["CL", "ST"]} />
                
                <PrivateRoute path="/manager-lodgify-integration" component={ManagerLodgifyFormPage} allowedRoles={["MN", "ST"]}/>
                <PrivateRoute path="/manager-lodgify-list" component={LodgifyIntegrationListPage} allowedRoles={["MN", "ST"]}/>

                <PrivateRoute path="/cleaner-alt-ical-alluradirect" component={CleanerAlluraDirectIntegration} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/manager-alt-ical-alluradirect" component={ManagerAlluraDirectIntegration} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/manager-inventory-template" component={ManagerInventoryTemplate} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/cleaner-inventory-template" component={CleanerInventoryTemplate} allowedRoles={["CL", "ST"]} />

                <PrivateRoute path="/cleaner-property-new-inventory-report" component={CleanerPropertyNewInventoryReport} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/manager-property-new-inventory-report" component={ManagerPropertyNewInventoryReport} allowedRoles={["MN", "ST"]} />
                
                <PrivateRoute path="/manager-sms-inbox/:remote_phone?" component={ManagerSmsInbox} allowedRoles={["MN"]}/>
                <PrivateRoute path="/cleaner-sms-inbox/:remote_phone?" component={CleanerSmsInbox} allowedRoles={["CL"]}/>

                <PrivateRoute path="/cleaner-locations" component={CleanerCompanyGeoLocations} allowedRoles={["CL"]}/>

                <PrivateRoute path="/manager-alluraDirect-integration" component={ManagerAlluraDirectFormPage} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/manager-alluraDirect-list" component={AlluraDirectIntegrationListPage} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/cleaner-hhs-weekly-invoice-new-report" component={CleanerHHSWeeklyInvoiceNewReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/admin-hhs-weekly-invoice-new-report" component={AdminHHSWeeklyInvoiceNewReport} allowedRoles={["SA", "AD"]}/>
                
                <PrivateRoute path="/cleaner-payroll-report" component={CleanerPayrollReport} allowedRoles={["CL", "ST"]}/>
                <PrivateRoute path="/manager-payroll-report" component={ManagerPayrollReport} allowedRoles={["MN", "ST"]}/>
                
                <PrivateRoute path="/cleaner-update-recurring" component={CleanerUpdateRecurringAppointment} allowedRoles={["CL", "ST"]} />
                <PrivateRoute path="/manager-update-recurring" component={ManagerUpdateRecurringAppointment} allowedRoles={["MN", "ST"]} />

                <PrivateRoute path="/cleaner-directory" component={CleanerDirectory} allowedRoles={["MN", "SA", "AD", "ST"]} />

                <PrivateRoute path="/manager-quote-requests" component={ManagerQuoteRequestsPage} allowedRoles={["MN", "ST"]} />
                <PrivateRoute path="/cleaner-quote-requests" component={CleanerQuoteRequestsPage} allowedRoles={["CL", "ST"]} />

                <PublicRoute path="/signup" component={Signup} />
                <PublicRoute path="/cleaner-signup" component={CleanerSignup} />
                <PublicRoute path="/manager-signup" component={ManagerSignup} />
                <PublicRoute path="/signup-thankyou" component={SignupThankyou} />
                <PublicRoute path="/unsubscribe" component={UnsubscribePage} />
                <Route path="/:lang?/appointment/preview" component={AppointmentPreviewPage} />
                <Route path="/cleaner-merged-tracker-report" component={CleanerMergeTrackReport}/>
                <Route path="/client-invoice-payment" component={ClientPayment} />
                <Route path="/rc-invoice-payment" component={RcEmailInvoicePayment} />
                <Route path="/UnauthoriseAccess" component={UnauthoriseAccess} />
                <Route path="/ErrorPage" component={ErrorPage} />
                <Route path="/logout" component={Logout} />
                <Route path="/session-error" component={SessionError} />
                <Route path="/" component={RedirectHandler} />
            </Switch>
            </Suspense>
        </BrowserRouter>
    </>
    );
};
