import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Form, Tab, Nav, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { microServices } from "../../../config/global";
import { getUser } from "../../../redux/user/action";
import { tilledFormValidation } from "../../../utils/cardValidation";
import { tilledTypeAch, tilledTypeCard, TILLED_ACH_LABEL,TILLED_CC_LABEL } from "../../../utils/paymentConstUtil";
import { getTilled,BANKTRANSFER, buildForm, cancelPaymentIntent, createInvoicePaymentIntent, createPaymentIntent, CREDITCARD, cancelRcPaymentIntent, createPaymentMethod, saveWalletDetials, USA_COUNTRY, } from "../../../utils/tilledUtil";
import { countryWiseStateDropdown, whiteSpaceHandler } from "../../../utils/utils";
import CustomSelect from "../../atoms/CustomSelect";
import InputText from "../../atoms/InputText";
import { LanguageContext } from "../../containers/Language";
import AchAccountFileds from "../TilledForms/achAccountFileds";
import CreditCardFileds from "../TilledForms/creditCardFileds";
import { getCompanyWallet } from "../../../redux/Billing/action";
import { isLoader } from "../../../redux/global/action";


const TilledPayment = React.forwardRef((props, ref) => {


    const { localised } = useContext(LanguageContext);
    const countries = useSelector((state) => state.global.countries) || [];
    const [stateOptions, setStatesOptions] = useState([]);
    const [active, setActive] = useState(Number(props.tilledCCId));
    const [accountType, selectedAccountType] = useState([]);
    const [tilledError, setTilledError] = useState('');
    const userInformation = useSelector((state) => state.user.user) || [];
    const [userProfile, setUserProfile] = useState([]);
    const userProfileDetails = useSelector((state) => state.authentication.profile);
    const dispatch = useDispatch();
    const [isSavePayment, setIsSavePayment] = useState(false);
    
    const handleSavePaymentChange = (e) => {
    setIsSavePayment(e.target.checked);
    };

   
 
    async function handleSubmit(event) {

        let isError = false;
        const thisType = active === props.tilledCCId ? CREDITCARD : BANKTRANSFER ;
        const saveMethod= active === props.tilledCCId ? CREDITCARD : BANKTRANSFER ;
        let formError = tilledFormValidation(props.tilledPayloadObj, true);
        for (const key in formError) {
            if (formError[key]) {
                isError = true;
            }
        }
        const { tilledfrm, form } = thisType;

        props.setFormError(formError);

        if (isError) {
            return;
        }
        
        if (!form.validate()) {
            toast.error('Payment details required!');
            return;
        }
        props.setIsLoading(true);
        let cardtypeLabel = props.tilledPayloadObj.type == 'card' ? TILLED_CC_LABEL : TILLED_ACH_LABEL;
        let cardType = props.tilledPayloadObj.type == 'card' ? props.tilledCCId : props.tilledACHId;
        let invoicePaymentForm = { ...props.invoicePayForm, autopay_method: cardtypeLabel, payment_method: cardType, isSavePayment: isSavePayment, };
        props.setInvoicePayForm(invoicePaymentForm);
        let cancelInvoiceForm = {};
        
        let payIntentEndPoint = microServices.base + '/v1/invoice/payment/tilled';
        let cancelPayIntentEndPoint = microServices.base + '/v1/invoice/payment/tilled/failed';
        
        if(props.isAdminIvoice){
            payIntentEndPoint = microServices.base + '/v1/admin/invoice/payment/tilled';
            cancelPayIntentEndPoint = microServices.base + '/v1/admin/invoice/payment/tilled/failed/';
        }

        

        let payIntent = createPaymentIntent(payIntentEndPoint, invoicePaymentForm);
        payIntent.then(response => {

            cancelInvoiceForm = {
                ...invoicePaymentForm,
                invoice_adjustment_id: response?.data?.data?.invoice_adjustment_id,
            }
            
            if (response?.data?.success) {
                let clientSecret = response?.data?.data?.client_secret;
                props.setIsLoading(true);
                let payAmt = createInvoicePaymentIntent(thisType, clientSecret, props.tilledPayloadObj);
                payAmt.then(
                    (payment) => {
                        
                        // props.setIsLoading(false);
                        props.setShowTilledPayModal(false);
                       
                            dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant: 'success',
                                    message: (cardType == props.tilledCCId) ? localised.tilledCCSuccess : localised.tilledAchSuccessPmInvoice,
                                    invoice_id: cancelInvoiceForm.invoice_id,
                                    componentName: 'TilledPay',
                                    isFromGlobal:true,
                                }
                            })
                       
                      },
                    (error) => {
                        
                        setTilledError(error.message);
                       
                        props.setIsLoading(false);
                        if (!props.isAdminIvoice) {
                            cancelPaymentIntent(cancelPayIntentEndPoint, cancelInvoiceForm).then((res)=>{
                                dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant: 'danger',
                                        message: localised.tilledPaymentFailed,
                                        invoice_id: cancelInvoiceForm.invoice_id,
                                        componentName: 'TilledPay'
                                    }
                                })
                            });
                        } else {
                            cancelRcPaymentIntent(cancelPayIntentEndPoint + invoicePaymentForm.invoice_id).then((res)=>{
                                dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant: 'danger',
                                        message: localised.tilledPaymentFailed,
                                        invoice_id: cancelInvoiceForm.invoice_id,
                                        componentName: 'TilledPay'
                                    }
                                })
                            });
                        }

                      
                    },
                )

                if (props.saveCard) {
                   addWalletDetails(saveMethod, props.tilledPayloadObj);
                }
               
            }

        }).catch((error) =>{
            // props.setIsLoading(false); 
          toast.error(error.response.data?.message?.messages)
         
        }).finally(() => {
            props.setIsLoading(false);
        });

    }

    const addWalletDetails = (tiledForm, tilledPayloadObj, tiledObj) => {

        createPaymentMethod(tiledForm, tilledPayloadObj).then(
            (payment) => {

                let formData = {
                    id: payment.id,
                    type: active,
                }
                saveWalletDetials(formData).then((res) => {

                })
                props.setIsLoading(false);
            },
            (error) => {
            }
        )
    }

    const handleAchCardChange = (e) => {
        const { name, value } = e.target;
        let tilledOb = {
            ...props.tilledPayloadObj,
            ach_debit: {
                ...props.tilledPayloadObj.ach_debit,
                [name]: value
            }
        };
        let cardErr = props.handleValidations(name, value);

        props.setFormError({ ...props.formError, ...cardErr });
        props.setTilledPayloadObj(tilledOb);
    }

    const handleAccountTypeChange = (selectedValue) => {
        selectedAccountType(selectedValue);
        let tilledOb = {
            ...props.tilledPayloadObj,
            ach_debit: {
                ...props.tilledPayloadObj.ach_debit,
                account_type: selectedValue.value
            }

        };
        let typErr = props.handleValidations('account_type', selectedValue?.value)

        props.setFormError({ ...props.formError, ...typErr });
        props.setTilledPayloadObj(tilledOb);
    }

    const handleCcChange = (e) => {
        const { name, value } = e.target;

        let tilledOb = {
            ...props.tilledPayloadObj,
            billing_details: {
                ...props.tilledPayloadObj.billing_details,
                [name]: value
            }

        };
        let ccErrr = props.handleValidations(name, value);
        props.setFormError({ ...props.formError, ...ccErrr });
        props.setTilledPayloadObj(tilledOb);
    }

    const tilledModifyObj = (name, value, isStateClear = false) => {
        let tilledOb = {
            ...props.tilledPayloadObj,
            billing_details: {
                ...props.tilledPayloadObj.billing_details,
                address: {
                    ...props.tilledPayloadObj.billing_details.address,
                    [name]: value
                }
            }
        };
        if (isStateClear) {
            tilledOb = {
                ...tilledOb,
                billing_details: {
                    ...tilledOb.billing_details,
                    address: {
                        ...tilledOb.billing_details.address,
                        state: '',
                    }
                }
            }
        }

        return tilledOb;
    }
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        props.setTilledPayloadObj(tilledModifyObj(name, value));
        let addressErr = props.handleValidations(name, value);
        props.setFormError({ ...props.formError, ...addressErr });
    }

    const handleCountryChange = (selectedValue) => {
        let selectedValues = (selectedValue) ? selectedValue : [];

        props.selectedCountry(selectedValues);

        setStatesOptions([]);
        props.selectedState([]);
        props.setTilledPayloadObj(tilledModifyObj('country', selectedValue?.value, true));
        props.getCountryWiseStates(selectedValues);
        let countryErr = props.handleValidations('country', selectedValue?.value)
        props.setFormError({ ...props.formError, ...countryErr });

    };


    const handleStateChange = (selectedValue) => {
        props.selectedState(selectedValue);
        props.setTilledPayloadObj(tilledModifyObj('state', selectedValue?.short_name));
        let stateErr = props.handleValidations('state', selectedValue?.value)

        props.setFormError({ ...props.formError, ...stateErr });
    };


    const radioHandlerTilledCc = () => {
        setActive(props.tilledCCId)
        let tilledD = getResetTilledData();
        props.selectedCountry( (userInformation?.Address?.Country?.country_code) ? { value: userInformation?.Address?.Country?.country_code, label: userInformation?.Address?.Country?.country } : USA_COUNTRY);
        props.selectedState( (userInformation?.state_abbbreaviation) ? { value: userInformation?.state_abbbreaviation, label: userInformation?.Address?.State?.state_name }:'');
       
        let ccFrorm = { ...tilledD};
        props.setTilledPayloadObj({ ...ccFrorm, type: 'card' })
        props.setSaveCard(false)
    };

    const radioHandlerTilledAch = () => {
        setActive(props.tilledACHId)
        let tilledD = getResetTilledData();
        props.selectedCountry( (userInformation?.Address?.Country?.country_code) ? { value: userInformation?.Address?.Country?.country_code, label: userInformation?.Address?.Country?.country } : USA_COUNTRY);
        props.selectedState( (userInformation?.state_abbbreaviation) ? { value: userInformation?.state_abbbreaviation, label: userInformation?.Address?.State?.state_name }:'');
       
        let achFrorm = { ...tilledD, ...tilledTypeAch };
        props.setTilledPayloadObj({ ...achFrorm, type: 'ach_debit' })
        props.setSaveCard(false)
    };

    useEffect(() => {
        if (props.countryWiseStates) {
            let states = countryWiseStateDropdown(props.countryWiseStates);
            setStatesOptions(states);
        }
    }, [props.countryWiseStates]);

    const clearTiledForm = () => {
        let countryObj1 = (userInformation?.Address?.Country?.country_code) ?  { value: userInformation?.Address?.Country?.country_code, label: userInformation?.Address?.Country?.country } : USA_COUNTRY
        let tilledObjNew= {
          ...props.tilledPayloadObj,
          billing_details: {
            ...props.tilledPayloadObj.billing_details,
            address: {
              ...props.tilledPayloadObj.billing_details.address,
              street: (userInformation?.Address?.address) ? userInformation?.Address?.address:'',
              state: (userInformation?.state_abbbreaviation) ? userInformation?.state_abbbreaviation :'',
              city: (userInformation?.Address?.city) ? userInformation?.Address?.city :'',
              zip: (userInformation?.Address?.postal_code) ? userInformation?.Address?.postal_code :'',
              country: (userInformation?.Address?.Country?.country_code) ? userInformation?.Address?.Country?.country_code :USA_COUNTRY.value ,
            },
            name: (userInformation.name) ? (userInformation.name) :'',
            email: (userInformation.email)? userInformation.email :'',
            phone: (userInformation.phone) ? userInformation.phone:'',
          }
        }
     
        if(props.tilledPayloadObj.type=='card'){
          tilledObjNew={...tilledObjNew,...tilledTypeCard}
        }else if(props.tilledPayloadObj.type=='ach_debit'){
          tilledObjNew={...tilledObjNew,...tilledTypeAch}
        }
        props.setExpiryDate('')
        props.setTilledPayloadObj(tilledObjNew)
        props.selectedCountry(countryObj1);
        props.selectedState((userInformation?.state_abbbreaviation) ? { value: userInformation?.state_abbbreaviation, label: userInformation?.Address?.State?.state_name }: '');
        props.setSaveCard(false)
        props.getCountryWiseStates(countryObj1);
    }
    
     useEffect(() => {
        if (userProfileDetails) {
          setUserProfile(userProfileDetails);
          let id = userProfileDetails.user_id;
    
          if (id) {
            dispatch(getUser({ id }));
          }
        }
      }, [userProfileDetails]);
    
      useEffect(() => {
        if (Object.keys(userInformation).length > 0) {
        let countryObj = (userInformation?.Address?.Country?.country_code) ?  { value: userInformation?.Address?.Country?.country_code, label: userInformation?.Address?.Country?.country } : USA_COUNTRY
        
          let tilledPayload = {
            ...props.tilledPayloadObj,
            type:'card',
            billing_details: {
                ...props.tilledPayloadObj.billing_details,
                address: {
                  ...props.tilledPayloadObj.billing_details.address,
                  street: (userInformation?.Address?.address) ? userInformation?.Address?.address:'',
                  state: (userInformation?.state_abbbreaviation) ? userInformation?.state_abbbreaviation :'',
                  city: (userInformation?.Address?.city) ? userInformation?.Address?.city :'',
                  zip: (userInformation?.Address?.postal_code) ? userInformation?.Address?.postal_code :'',
                  country: (userInformation?.Address?.Country?.country_code) ? userInformation?.Address?.Country?.country_code :USA_COUNTRY.value ,
                },
                name: (userInformation.name) ? (userInformation.name) :'',
                email: (userInformation.email)? userInformation.email :'',
                phone: (userInformation.phone) ? userInformation.phone:'',
              }
          };
          props.setTilledPayloadObj({...tilledPayload,...tilledTypeCard});
          props.selectedCountry(countryObj);
          props.selectedState((userInformation?.state_abbbreaviation) ? { value: userInformation?.state_abbbreaviation, label: userInformation?.Address?.State?.state_name }: '');
          props.setTilledType('card')
          props.getCountryWiseStates(countryObj);
 
        }
      }, [userInformation]);
  
      const getResetTilledData =()=>{
        return {
            ...props.tilledPayloadObj,
            billing_details: {
              ...props.tilledPayloadObj.billing_details,
              address: {
                ...props.tilledPayloadObj.billing_details.address,
                street: (userInformation?.Address?.address) ? userInformation?.Address?.address:'',
                state: (userInformation?.state_abbbreaviation) ? userInformation?.state_abbbreaviation :'',
                city: (userInformation?.Address?.city) ? userInformation?.Address?.city :'',
                zip: (userInformation?.Address?.postal_code) ? userInformation?.Address?.postal_code :'',
                country: (userInformation?.Address?.Country?.country_code) ? userInformation?.Address?.Country?.country_code :USA_COUNTRY.value ,
              },
              name: (userInformation.name) ? (userInformation.name) :'',
              email: (userInformation.email)? userInformation.email :'',
              phone: (userInformation.phone) ? userInformation.phone:'',
            }
          };
      }

    return (
        <> 
        {/* {props.backDatedInvoice?.is_back_dated && 
            <Alert key={'warning'} variant={'warning'}>
                {localised.rcInvoicePayFirst + ' ' + props.backDatedInvoice?.old_invoice?.invoice_number}
            </Alert>
            } */}
            <div className='modal-content-card pt-2'>
                <div className="modal-heading">
                    <h3>{localised.paymentMethod}</h3>
                </div>
               
                <Row>
                    <Col md={props.isAdminIvoice ? '3' : '6'}>
                        <Form.Group className="form-group">
                            <div className='d-flex align-items-center flex-wrap'>
                                <div>
                                    <Form.Check
                                        type="radio" name='radio'
                                        id='inline-radio-1' label={localised.cc}
                                        onClick={(e) => radioHandlerTilledCc()}
                                        checked={props.tilledPayloadObj.type == 'card'}

                                    />
                                </div>

                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={props.isAdminIvoice ? '3' : '6'}>
                        <Form.Group className="form-group">
                            <div className='d-flex align-items-center flex-wrap'>
                                <div>
                                    <Form.Check
                                        type="radio" name='radio'
                                        id='inline-radio-1' label={localised.ach}
                                        onClick={(e) => radioHandlerTilledAch()}
                                        checked={props.tilledPayloadObj.type == 'ach_debit'}

                                    />
                                </div>

                            </div>
                        </Form.Group>
                    </Col>
                    { props.isAdminIvoice && (props?.isWallet == undefined) &&
                    <Col md="3" lg="3">
                        <Form.Group className="form-group">
                        <div className='d-flex align-items-center flex-wrap'>
                            <Form.Label>{localised.amount}</Form.Label>
                            <InputText
                                id={'amount'}
                                name={'amount'}
                                type={'text'}
                                value={props.invoicePayForm.amount}
                                placeholder={'$0.00'}
                                required={'required'}
                                readonly={'readonly'}
                            />
                            </div>
                        </Form.Group>
                    </Col>
                    }
                </Row>
            </div>
            <div className='modal-content-card pb-2'>
                <div className="modal-heading d-flex">
                    <h3>{localised.paymentDetails} </h3>
                </div>

                <Row>

                    {(active === props.tilledCCId) ?
                        <CreditCardFileds
                            {...props}
                            tilledError={tilledError}
                            handleCcChange={handleCcChange}
                            handleAchCardChange={handleAchCardChange}
                        /> : <AchAccountFileds
                            {...props}
                            tilledError={tilledError}
                            handleAccountTypeChange={handleAccountTypeChange}
                            handleAchCardChange={handleAchCardChange}
                        />
                    }


                </Row>
                {props.isSavePaymentDetail && (
                    <Row>
                        <Col md="12">
                        <Form.Check 
                        type="checkbox" 
                        id="save-details" 
                        label={localised.save+" "+localised.and+" "+localised.pay} 
                        className="me-2 cursor-pointer"
                        onChange={handleSavePaymentChange}
                        />
                        </Col>
                    </Row>
                )}
            </div>
            <div className='modal-content-card pb-2'>
                <div className="modal-heading d-flex">
                    <h3>{localised.billingAddressInformation} </h3>
                </div>
                <Row>
                    <Col>
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.streetAddress}</Form.Label>
                            <Form.Control
                                as="textarea"
                                name={'street'}
                                rows={2}
                                value={props.tilledPayloadObj?.billing_details?.address?.street}
                                onChange={(e) => handleAddressChange(e)}
                                isInvalid={(props.formError?.street_error) ? !!props.formError.street_error : false}
                                onKeyPress={!(props.isAutoPayRequest) ? props.handleEnterPressOnTilled : (e) => { return }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.formError && props.formError.street_error && props.formError.street_error.length > 0 && (
                                    props.formError.street_error
                                )}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.country}</Form.Label>
                            <CustomSelect
                                id={'country'}
                                name={'country'}
                                value={props.country}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                options={countries}
                                onChange={handleCountryChange}
                                isClearable={true}
                                isInvalid={(props.formError?.country_error) ? !!props.formError.country_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                             <Form.Control.Feedback type="invalid"  style={{ display: (props.formError && props.formError.country_error && props.formError.country_error.length > 0) ? "block": "none"}}>
                                {
                                    props.formError.country_error
                                }</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.state}</Form.Label>
                            <CustomSelect
                                id={'state'}
                                name={'state'}
                                value={props.state}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                options={stateOptions}
                                onChange={handleStateChange}
                                isClearable={true}
                                isInvalid={(props.formError?.state_error) ? !!props.formError.state_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid" style={{ display: (props.formError && props.formError.state_error && props.formError.state_error.length > 0) ? "block": "none"}}>
                                {props.formError && props.formError.state_error && props.formError.state_error.length > 0 && (
                                    props.formError.state_error
                                )}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.city}</Form.Label>
                            <InputText
                                id={'city'}
                                name={'city'}
                                type={'text'}
                                value={props.tilledPayloadObj?.billing_details?.address?.city}
                                placeholder={localised.city}
                                onChange={(e) => handleAddressChange(e)}
                                required={'required'}
                                isInvalid={(props.formError?.city_error) ? !!props.formError.city_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.formError && props.formError.city_error && props.formError.city_error.length > 0 && (
                                    props.formError.city_error
                                )}</Form.Control.Feedback>

                        </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.zipCode}</Form.Label>
                            <InputText
                                id={'zipCode'}
                                name={'zip'}
                                type={'text'}
                                value={props.tilledPayloadObj?.billing_details?.address?.zip}
                                placeholder={localised.zipCode}
                                onChange={(e) => handleAddressChange(e)}
                                required={'required'}
                                isInvalid={(props.formError?.zip_error) ? !!props.formError.zip_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.formError && props.formError.zip_error && props.formError.zip_error.length > 0 && (
                                    props.formError.zip_error
                                )}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
            <div className='modal-content-card pb-2'>
                <Row>
                    <Col md="12">
                        <div className="text-end pb-2 pt-2 edit-appt-btns">
                            <Button variant="secondary" form='my-form' type="reset"
                            onClick={(e)=>{clearTiledForm(e)}}
                            >
                                {localised.reset}
                            </Button>
                            <span> </span>
                            <Button disabled={props.isLoading } variant="primary" form='my-form' type="button"
                                onClick={ handleSubmit}
                            >
                                {(props.isLoading) ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (props.btnLabel) ? props.btnLabel : localised.save}
                            </Button>

                        </div>
                    </Col>
                </Row>
            </div>

        </>
    )

})
export default TilledPayment;
