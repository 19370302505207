import React, { useState, useContext, useEffect } from "react";
import { Col, Row, Form, Tab, Nav, Table, Button } from "react-bootstrap";
import CustomSelect from "../../atoms/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "../../containers/Language";
import InputText from "../../atoms/InputText";
import {
  countryWiseStateDropdown,
  emailValidation,
  isEmptyNullUndefined,
  whiteSpaceHandler,
} from "../../../utils/utils";
import styled from "styled-components";
import {
  CardpointAchType,
  PAYMENT_TILLED_ACH,
  filterPaymentSupportCountry,
} from "../../../utils/paymentConstUtil";

import {
  handleValidations,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../../../utils/cardValidation";
import { USA_COUNTRY } from "../../../utils/tilledUtil";

const CardpointeWallet = React.forwardRef((props, ref) => {
  const [accountTypeOptions, setAccountTypeOptions] =
    useState(CardpointAchType);
  const { localised } = useContext(LanguageContext);
  const countries = useSelector((state) => state.global.countries) || [];
  const [accountType, selectedAccountType] = useState([]);
  const [stateOptions, setStatesOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const handleCardChange = (e) => {
    const { name, value } = e.target;
    let tilledOb = {};
    if (name == "number") {
      tilledOb = {
        ...props.cardpointePayloadObj,
        card: {
          ...props.cardpointePayloadObj.card,
          [name]: formatCreditCardNumber(value),
        },
      };
    } else if (name == "cvc") {
      tilledOb = {
        ...props.cardpointePayloadObj,
        card: {
          ...props.cardpointePayloadObj.card,
          [name]: formatCVC(value),
        },
      };
    }

    let nameErr = handleValidations(name, value);

    props.setCardpointeFormError({ ...props.cardpointeFormError, ...nameErr });
    props.setCardpointePayloadObj(tilledOb);
  };

  const handleAchCardChange = (e) => {
    const { name, value } = e.target;
    let tilledOb = {
      ...props.cardpointePayloadObj,
      ach_debit: {
        ...props.cardpointePayloadObj.ach_debit,
        [name]: value,
      },
    };
    let cardErr = handleValidations(name, value);
    props.setCardpointeFormError({ ...props.cardpointeFormError, ...cardErr });
    props.setCardpointePayloadObj(tilledOb);
  };

  const handleCardExpiryChange = (e) => {
    const { name, value } = e.target;
    if (e.target.value != "undefined" || e.target.value) {
      if (e.target.value.substring(0, 1) <= "1") {
        props.setExpiryDate(formatExpirationDate(e.target.value));
        let expYerDateErr = handleValidations(name, value);
        props.setCardpointeFormError({
          ...props.cardpointeFormError,
          ...expYerDateErr,
        });
      } else {
        let newValue = e.target.value.padStart(2, "0");
        props.setExpiryDate(formatExpirationDate(newValue));
        let expYerDateErr = handleValidations(name, newValue);
        props.setCardpointeFormError({
          ...props.cardpointeFormError,
          ...expYerDateErr,
        });
      }
    }
  };

  const onCardExpiryChange = (e) => {
    const [exp_month, exp_year] = e.target.value.split("/");
    let tilledOb = {
      ...props.cardpointePayloadObj,
      card: {
        ...props.cardpointePayloadObj.card,
        exp_month: exp_month,
        exp_year: exp_year,
      },
    };

    props.setCardpointePayloadObj(tilledOb);
  };
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    props.setCardpointePayloadObj(cardpointeModifyObj(name, value));
    let addressErr = handleValidations(name, value);
    props.setCardpointeFormError({
      ...props.cardpointeFormError,
      ...addressErr,
    });
  };

  const cardpointeModifyObj = (name, value, isStateClear = false) => {
    let tilledOb = {
      ...props.cardpointePayloadObj,
      billing_details: {
        ...props.cardpointePayloadObj.billing_details,
        address: {
          ...props.cardpointePayloadObj.billing_details.address,
          [name]: value,
        },
      },
    };
    if (isStateClear) {
      tilledOb = {
        ...tilledOb,
        billing_details: {
          ...tilledOb.billing_details,
          address: {
            ...tilledOb.billing_details.address,
            state: "",
          },
        },
      };
    }

    return tilledOb;
  };

  useEffect(() => {
    if (props.countryWiseStates) {
      let states = countryWiseStateDropdown(props.countryWiseStates);
      setStatesOptions(states);
    }
  }, [props.countryWiseStates]);

  const handleCountryChange = (selectedValue) => {
    let selectedValues = selectedValue ? selectedValue : [];

    props.setCardpointeSelectedCountry(selectedValues);

    setStatesOptions([]);
    props.setCardpointeSelectedState([]);
    props.setCardpointePayloadObj(
      cardpointeModifyObj("country", selectedValue?.value, true)
    );
    props.getCountryWiseStates(selectedValues);
    let countryErr = handleValidations("country", selectedValue?.value);
    props.setCardpointeFormError({
      ...props.cardpointeFormError,
      ...countryErr,
    });
  };

  const handleStateChange = (selectedValue) => {
    props.setCardpointeSelectedState(selectedValue);
    props.setCardpointePayloadObj(
      cardpointeModifyObj("state", selectedValue?.short_name)
    );
    let stateErr = handleValidations("state", selectedValue?.value);
    props.setCardpointeFormError({ ...props.cardpointeFormError, ...stateErr });
  };

  const handleAccountTypeChange = (selectedValue) => {
    selectedAccountType(selectedValue);
    let tilledOb = {
      ...props.cardpointePayloadObj,
      ach_debit: {
        ...props.cardpointePayloadObj.ach_debit,
        account_type: selectedValue.value,
      },
    };
    let typErr = handleValidations("account_type", selectedValue?.value);
    props.setCardpointeFormError({ ...props.cardpointeFormError, ...typErr });
    props.setCardpointePayloadObj(tilledOb);
  };

  const Error = styled.span`
    font-size: 12px;
    color: red;
  `;

  const handleCcChange = (e) => {
    const { name, value } = e.target;

    let tilledOb = {
      ...props.cardpointePayloadObj,
      billing_details: {
        ...props.cardpointePayloadObj.billing_details,
        [name]: value,
      },
    };

    let ccErrr = handleValidations(name, value);
    props.setCardpointeFormError({ ...props.cardpointeFormError, ...ccErrr });
    props.setCardpointePayloadObj(tilledOb);
  };

  const handleAgreedTerms = (e) => {
    props.setAgreedTerms(!props.agreedTerms);
    props.setIsSubmitted(false);
  };

  useEffect(() => {
    if(countries) {
      setCountryOptions(filterPaymentSupportCountry(countries));
    }
  },[countries])

  useEffect(() => {
    return () => {
      if(!isEmptyNullUndefined(props?.setIsSubmitted)) {
        props?.setIsSubmitted(false);
      }
    };
  },[]);

  return (
    <>
      {props.isAutoPayRequest && (
        <div className="modal-content-card pt-2">
          <div className="modal-heading">
            <h3>{localised.paymentMethod}</h3>
          </div>

          <Row>
            <Col md="6">
              <Form.Group className="form-group">
                <div className="d-flex align-items-center flex-wrap">
                  <div>
                    <Form.Check
                      type="radio"
                      name="radio"
                      id="inline-radio-1"
                      label={localised.cc}
                      onClick={(e) => props.radioHandlerCardpointeCc()}
                      checked={props.cardpointePayloadObj.type == "card"}
                    />
                  </div>
                  <div className="ms-md-4">
                    <Form.Check
                      type="radio"
                      name="radio"
                      id="inline-radio-2"
                      label={localised.ach}
                      onClick={(e) => props.radioHandlerCardpointeAch()}
                      checked={props.cardpointePayloadObj.type == "ach_debit"}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
            {props.fromRCInvoicePage && (
              <Col md="4">
                <Form.Group className="form-group">
                        <div className='d-flex align-items-center flex-wrap'>
                            <Form.Label>{localised.amount}</Form.Label>
                            <InputText
                                id={'amount'}
                                name={'amount'}
                                type={'text'}
                                value={props.invoicePayForm.amount}
                                placeholder={'$0.00'}
                                required={'required'}
                                readonly={'readonly'}
                            />
                            </div>
                        </Form.Group>
              </Col>
            )}
          </Row>
        </div>
      )}

      <div className="modal-content-card pb-2">
        <div className="modal-heading d-flex">
          <h3>{localised.paymentDetails} </h3>
        </div>

        <Row>
          {props.cardpointePayloadObj.type == "ach_debit" && (
            <>
              <Col md="6" lg="6">
                <Form.Group className="required form-group">
                  <Form.Label>{localised.accountHolderName}</Form.Label>
                  <InputText
                    id={"accountHolderName"}
                    name={"account_holder_name"}
                    type={"text"}
                    placeholder={localised.accountHolderName}
                    value={
                      props.cardpointePayloadObj?.ach_debit?.account_holder_name
                    }
                    onChange={(e) => handleAchCardChange(e)}
                    isInvalid={
                      props.cardpointeFormError?.account_holder_name_error
                        ? !!props.cardpointeFormError.account_holder_name_error
                        : false
                    }
                    required={"required"}
                    onKeyPress={
                      !props.isAutoPayRequest
                        ? props.handleEnterPressOnTilled
                        : (e) => {
                            return;
                          }
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.cardpointeFormError &&
                      props.cardpointeFormError.account_holder_name_error &&
                      props.cardpointeFormError.account_holder_name_error
                        .length > 0 &&
                      props.cardpointeFormError.account_holder_name_error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="6" lg="6">
                <Form.Group className="required form-group">
                  <Form.Label>{localised.accountType}</Form.Label>
                  <CustomSelect
                    // menuPortalTarget={document.querySelector('body')}
                    id={"accountType"}
                    name={"account_type"}
                    closeMenuOnSelect={true}
                    value={accountType}
                    isMulti={false}
                    onChange={(e) => handleAccountTypeChange(e)}
                    isInvalid={
                      props.cardpointeFormError?.account_type_error
                        ? !!props.cardpointeFormError.account_type_error
                        : false
                    }
                    options={accountTypeOptions}
                    onKeyPress={
                      !props.isAutoPayRequest
                        ? props.handleEnterPressOnTilled
                        : (e) => {
                            return;
                          }
                    }
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className={
                      props.cardpointeFormError?.account_type_error
                        ? "d-flex"
                        : "d-none"
                    }
                  >
                    {props.cardpointeFormError &&
                      props.cardpointeFormError.account_type_error &&
                      props.cardpointeFormError.account_type_error.length > 0 &&
                      props.cardpointeFormError.account_type_error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="6" lg="6">
                <Form.Group className="required form-group">
                  <Form.Label>{localised.accountNumber}</Form.Label>
                  <InputText
                    id={"accountNumber"}
                    name={"account_number"}
                    onKeyDown={whiteSpaceHandler}
                    onChange={(e) => handleAchCardChange(e)}
                    value={
                      props.cardpointePayloadObj?.ach_debit?.account_number
                    }
                    type={"text"}
                    placeholder={localised.accountNumber}
                    required={"required"}
                    onKeyPress={
                      !props.isAutoPayRequest
                        ? props.handleEnterPressOnTilled
                        : (e) => {
                            return;
                          }
                    }
                    isInvalid={
                      props.cardpointeFormError?.account_number_error
                        ? !!props.cardpointeFormError.account_number_error
                        : false
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.cardpointeFormError &&
                      props.cardpointeFormError?.account_number_error &&
                      props.cardpointeFormErro?.account_number_error.length >
                        0 &&
                      props.cardpointeFormError.account_number_error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="6" lg="6">
                <Form.Group className="required form-group">
                  <Form.Label>{localised.routingNumber}</Form.Label>
                  <InputText
                    id={"routingNumber"}
                    name={"routing_number"}
                    onChange={(e) => handleAchCardChange(e)}
                    type={"text"}
                    onKeyDown={whiteSpaceHandler}
                    value={
                      props.cardpointePayloadObj?.ach_debit?.routing_number
                    }
                    placeholder={localised.routingNumber}
                    required={"required"}
                    onKeyPress={
                      !props.isAutoPayRequest
                        ? props.handleEnterPressOnTilled
                        : (e) => {
                            return;
                          }
                    }
                    isInvalid={
                      props.cardpointeFormError?.routing_number_error
                        ? !!props.cardpointeFormError.routing_number_error
                        : false
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.cardpointeFormError &&
                      props.cardpointeFormError?.routing_number_error &&
                      props.cardpointeFormError?.routing_number_error.length >
                        0 &&
                      props.cardpointeFormError.routing_number_error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {props.fromRCInvoicePage &&  props.saveCardShow &&
                <Col md="6">
                <Form.Group className="form-group d-flex align-items-center pt-md-4 mb-md-0 mt-md-2">
                    <Form.Check
                    type="checkbox"
                    id="custom-switch"
                    label="Save this card"
                    checked={props.saveCard}
                    onClick={(e) => props.setSaveMethod(e)}
                    />
                </Form.Group>
                </Col>
            }
            </>
          )}
          {props.cardpointePayloadObj.type == "card" && (
            <>
              <Col md="6" lg="6">
                <Form.Group className="required form-group">
                  <Form.Label>{localised.billingEmail}</Form.Label>
                  <InputText
                    id={"billingEmail"}
                    name={"email"}
                    type={"text"}
                    placeholder={localised.billingEmail}
                    value={props.cardpointePayloadObj?.billing_details?.email}
                    onChange={(e) => handleCcChange(e)}
                    onKeyDown={whiteSpaceHandler}
                    required={"required"}
                    onKeyPress={
                      !props.isAutoPayRequest
                        ? props.handleEnterPressOnTilled
                        : (e) => {
                            return;
                          }
                    }
                    isInvalid={
                      props.cardpointeFormError?.email_error
                        ? !!props.cardpointeFormError.email_error
                        : false
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.cardpointeFormError &&
                      props.cardpointeFormError?.email_error &&
                      props.cardpointeFormError?.email_error.length > 0 &&
                      props.cardpointeFormError.email_error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="6" lg="6">
                <Form.Group className="required form-group">
                  <Form.Label>{localised.nameOnCard}</Form.Label>
                  <InputText
                    id={"nameOnCard"}
                    name={"name"}
                    type={"text"}
                    placeholder={localised.nameOnCard}
                    value={props.cardpointePayloadObj?.billing_details?.name}
                    onChange={(e) => handleCcChange(e)}
                    required={"required"}
                    onKeyPress={
                      !props.isAutoPayRequest
                        ? props.handleEnterPressOnTilled
                        : (e) => {
                            return;
                          }
                    }
                    isInvalid={
                      props.cardpointeFormError?.name_error
                        ? !!props.cardpointeFormError.name_error
                        : false
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.cardpointeFormError &&
                      props.cardpointeFormError?.name_error &&
                      props.cardpointeFormError?.name_error.length > 0 &&
                      props.cardpointeFormError.name_error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="6" lg="6">
                <Form.Group className="required form-group">
                  <Form.Label>{localised.cardNumber}</Form.Label>

                  <InputText
                    id={"cardNumber"}
                    name={"number"}
                    type={"text"}
                    placeholder={localised.cardNumber}
                    value={props.cardpointePayloadObj?.card?.number}
                    onChange={(e) => handleCardChange(e)}
                    pattern="[\d| ]{16,22}"
                    required={"required"}
                    onKeyDown={whiteSpaceHandler}
                    onKeyPress={
                      !props.isAutoPayRequest
                        ? props.handleEnterPressOnTilled
                        : (e) => {
                            return;
                          }
                    }
                    isInvalid={
                      props.cardpointeFormError?.number_error
                        ? !!props.cardpointeFormError.number_error
                        : false
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    {props.cardpointeFormError &&
                      props.cardpointeFormError?.number_error &&
                      props.cardpointeFormError?.number_error.length > 0 &&
                      props.cardpointeFormError.number_error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="6" lg="6">
                <Form.Group className="required form-group">
                  <Form.Label>{localised.cvc}</Form.Label>
                  <InputText
                    id={"cvc"}
                    name={"cvc"}
                    type={"text"}
                    value={props.cardpointePayloadObj?.card?.cvc}
                    placeholder={localised.cvc}
                    onChange={(e) => handleCardChange(e)}
                    required={"required"}
                    pattern="\d{4}"
                    onKeyPress={
                      !props.isAutoPayRequest
                        ? props.handleEnterPressOnTilled
                        : (e) => {
                            return;
                          }
                    }
                    isInvalid={
                      props.cardpointeFormError?.cvc_error
                        ? !!props.cardpointeFormError.cvc_error
                        : false
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.cardpointeFormError &&
                      props.cardpointeFormError?.cvc_error &&
                      props.cardpointeFormError?.cvc_error.length > 0 &&
                      props.cardpointeFormError.cvc_error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md="6" lg="6">
                <Form.Group className=" form-group">
                  <Form.Label>{localised.expiration}</Form.Label>
                  <InputText
                    id={"expiration"}
                    name={"exp_month_year"}
                    type={"text"}
                    placeholder="MM/YY"
                    value={props.expiryDate}
                    pattern="\d\d/\d\d"
                    onChange={(e) => handleCardExpiryChange(e)}
                    onBlur={(e) => onCardExpiryChange(e)}
                    required={"required"}
                    onKeyPress={
                      !props.isAutoPayRequest
                        ? props.handleEnterPressOnTilled
                        : (e) => {
                            return;
                          }
                    }
                    isInvalid={
                      props.cardpointeFormError?.exp_month_year_error
                        ? !!props.cardpointeFormError.exp_month_year_error
                        : false
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.cardpointeFormError &&
                      props.cardpointeFormError?.exp_month_year_error &&
                      props.cardpointeFormError?.exp_month_year_error.length >
                        0 &&
                      props.cardpointeFormError.exp_month_year_error}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {props.fromRCInvoicePage &&  props.saveCardShow &&
                <Col md="6">
                <Form.Group className="form-group d-flex align-items-center pt-md-4 mb-md-0 mt-md-2">
                    <Form.Check
                    type="checkbox"
                    id="custom-switch"
                    label="Save this card"
                    checked={props.saveCard}
                    onClick={(e) => props.setSaveMethod(e)}
                    />
                </Form.Group>
                </Col>
            }
          </>
          )}
        </Row>
      </div>
      <div className="modal-content-card pb-2">
        <div className="modal-heading d-flex">
          <h3>{localised.billingAddressInformation} </h3>
        </div>
        <Row>
          <Col>
            <Form.Group className="required form-group">
              <Form.Label>{localised.streetAddress}</Form.Label>
              <Form.Control
                as="textarea"
                name={"street"}
                rows={2}
                value={
                  props.cardpointePayloadObj?.billing_details?.address?.street
                }
                onChange={(e) => handleAddressChange(e)}
                isInvalid={
                  props.cardpointeFormError?.street_error
                    ? !!props.cardpointeFormError.street_error
                    : false
                }
                onKeyPress={
                  !props.isAutoPayRequest
                    ? props.handleEnterPressOnTilled
                    : (e) => {
                        return;
                      }
                }
              />
              <Form.Control.Feedback type="invalid">
                {props.cardpointeFormError &&
                  props.cardpointeFormError?.street_error &&
                  props.cardpointeFormError?.street_error.length > 0 &&
                  props.cardpointeFormError.street_error}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.country}</Form.Label>
                            <CustomSelect
                                id={'country'}
                                name={'country'}
                                value={props.cardpointeSelectedCountry}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                options={countryOptions}
                                onChange={handleCountryChange}
                                isClearable={true}
                                isInvalid={(props.cardpointeFormError?.country_error) ? !!props.cardpointeFormError?.country_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid"  style={{ display: (props.cardpointeFormError && props.cardpointeFormError?.country_error && props.cardpointeFormError?.country_error.length > 0) ? "block": "none"}}>
                                {
                                    props.cardpointeFormError?.country_error
                                }</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.state}</Form.Label>
                            <CustomSelect
                                id={'state'}
                                name={'state'}
                                value={props.cardpointeSelectedState}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                options={stateOptions}
                                onChange={handleStateChange}
                                isClearable={true}
                                isInvalid={(props.cardpointeFormError?.state_error) ? !!props.cardpointeFormError?.state_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid" style={{ display: (props.cardpointeFormError && props.cardpointeFormError?.state_error && props.cardpointeFormError?.state_error.length > 0) ? "block": "none"}}>
                                {props.cardpointeFormError && props.cardpointeFormError.state_error && props.cardpointeFormError.state_error.length > 0 && (
                                    props.cardpointeFormError.state_error
                                )}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.city}</Form.Label>
                            <InputText
                                id={'city'}
                                name={'city'}
                                type={'text'}
                                value={props.cardpointePayloadObj?.billing_details?.address?.city}
                                placeholder={localised.city}
                                onChange={(e) => handleAddressChange(e)}
                                required={'required'}
                                isInvalid={(props.cardpointeFormError?.city_error) ? !!props.cardpointeFormError?.city_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.cardpointeFormError && props.cardpointeFormError?.city_error && props.cardpointeFormError?.city_error.length > 0 && (
                                    props.cardpointeFormError.city_error
                                )}</Form.Control.Feedback>

                        </Form.Group>
                    </Col>
                    <Col md="6" lg="6">
                        <Form.Group className="required form-group">
                            <Form.Label>{localised.zipCode}</Form.Label>
                            <InputText
                                id={'zipCode'}
                                name={'zip'}
                                type={'text'}
                                value={props.cardpointePayloadObj?.billing_details?.address?.zip}
                                placeholder={localised.zipCode}
                                onChange={(e) => handleAddressChange(e)}
                                required={'required'}
                                isInvalid={(props.cardpointeFormError?.zip_error) ? !!props.cardpointeFormError?.zip_error : false}
                                onKeyPress={props.handleEnterPressOnTilled}
                            />
                            <Form.Control.Feedback type="invalid">
                                {props.cardpointeFormError && props.cardpointeFormError?.zip_error && props.cardpointeFormError?.zip_error.length > 0 && (
                                    props.cardpointeFormError.zip_error
                                )}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
      </div>
      {<div className="modal-content-card pb-2">
        <div className="modal-heading d-flex">
          <h3>{localised.autopayAgreementPayment} </h3>
        </div>
        <Row>
          <Col>
            <Form.Group className="required form-group only-checkbox">
              <Form.Check 
                type="checkbox"
                name="radio"
                key="radio1"
                id="inline-radio-2"
                checked={props.agreedTerms}
                onChange={(event) => handleAgreedTerms(event)}
                label=  {<>{localised.autopayAgreement} <span style={{ color: 'red' }}>*</span></>}
                className={props.isSubmitted && !props.agreedTerms ? "checkbox-warning" : ""}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>}
    </>
  );
});
export default CardpointeWallet;
