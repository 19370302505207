import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
/**
 * BillingMiddleware can be used to call Billing related API services
 * @param {*} store 
 * @returns 
 */
const BillingMiddleware = (store) => (next) => async (action) => {
  const result = next(action);
  let endpoint = microServices.base + '/v1/company/setting/billing/';
  switch (action.type) {
    case "FETCH_BILLING_CONFIG":
      store.dispatch(isLoader(true));
      APIservice
        .get(endpoint + '/' + action.payload.company_id)
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: 'SET_BILLING_CONFIG',
              payload: {
                data: response.data.data
              }
            });
          }
        })
        .catch((error) => {
          let err = { componentName: 'EditBilling', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;


    case "UPDATE_BILLING_CONFIG":
      store.dispatch(isLoader(true));
      APIservice
        .put(endpoint + '/' + action.payload.company_id, action.payload.formData)
        .then((response) => {
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: 'Billing Configuration updated successfully!',
              componentName: 'EditBilling'
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: 'Unable to save Billing Configuration!',
              }
            });
          } else {
            let err = { componentName: 'EditBilling', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;

    case "FETCH_PAYMENT_TYPE":
      const endpointPaymentType = microServices.base + '/v1/dropdown/PaymentType';

      APIservice
        .get(endpointPaymentType)
        .then((response) => {
          if (response && response.data) {
            let paymentTypes = [];
            response.data.data.forEach((pay) => {

              paymentTypes.push({
                value: pay.id,
                label: pay.type
              });
            });
          
            store.dispatch({
              type: 'SET_PAYMENT_TYPE',
              payload: {
                data: paymentTypes
              }
            });
          }
        })
        .catch((error) => {
          let err = { componentName: 'PaymentType', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
       
      break;

      case "UPDATE_PAYMENT_MODE":
        endpoint  = microServices.base+'/v1/company/setting/payment';
        store.dispatch(isLoader(true));
          APIservice
            .put(endpoint+'/'+action.payload.company_id, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Payment Mode updated successfully!',
                  componentName:'cleanerlist'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to update Payment Mode!',
                  }
                });
              } else {
                let err = {componentName:'cleanerlist',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "FETCH_PAYMENT_MODE":
          endpoint  = microServices.base+'/v1/company/setting/payment';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint + '/' + action.payload.company_id)
            .then((response) => {
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_PAYMENT_MODE',
                  payload: {
                    data: response.data.data
                  }
                });
              }
            })
            .catch((error) => {
              let err = { componentName: 'cleanerlist', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            })
          break;
          case "FETCH_PAYMENT_MODE_LIST":
            endpoint  = microServices.base+'/v1/dropdown/paymentMode';
            store.dispatch(isLoader(true));
            APIservice
              .get(endpoint)
              .then((response) => {
                if (response && response.data) {
                  let paymentModes = [];
                 
                  response.data.data.forEach((pay) => {
      
                    paymentModes.push({
                      value: pay.id,
                      label: pay.type
                    });
                  });
                
                  store.dispatch({
                    type: 'SET_PAYMENT_MODE_LIST',
                    payload: {
                      data: paymentModes
                    }
                  });
                }
              })
              .catch((error) => {
                let err = { componentName: 'cleanersPm', statusCode: error.response.status, data: error.response.data };
                store.dispatch(setError(err));
              })
              .finally(() => {
                store.dispatch(isLoader(false));
              })
            break;

            
      case "FETCH_PAYMENT_METHOD":
        const endpointPaymentMethod = microServices.base + '/v1/dropdown/companyWalletPaymentType';
        APIservice
          .get(endpointPaymentMethod)
          .then((response) => {
            if (response && response.data) {
              let paymentMethod = [];
              response.data.data?.forEach((pay) => {  
                paymentMethod.push({
                  value: pay.id,
                  label: pay.type
                });
              });
            
            store.dispatch({
              type: 'SET_PAYMENT_METHOD',
              payload: {
                data: paymentMethod
              }
            });
          }
        })
        .catch((error) => {
          let err = { componentName: 'PaymentMethod', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
        break;
         case "STORE_PAYMENT_CONFIG":
           endpoint= microServices.base + '/v1/company/payment/tilled';
                store.dispatch(isLoader(true));
               
                APIservice
                .post(endpoint, action.payload)
                .then((response)=>{
                  if (response) {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'success',
                        message: 'Payment Details Updated Successfully!',
                        componentName:'tilledConfigStore'
                      }
                    });
                   
                  }
                })
                .catch((error)=>{
                  let err = {componentName:'tilledConfigStore',statusCode: error.response.status, data: error.response.data.message};
                  store.dispatch(setError(err));
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
                break;
                case "FETCH_COMPANY_WALLET":
                  const endpointPaymentwallet= microServices.base + '/v1/company/payment/'+action?.payload?.paymentGateway;
                  APIservice
                    .get(endpointPaymentwallet)
                    .then((response) => {
                      if (response && response.data) {
                        
                        store.dispatch({
                          type: 'SET_COMPANY_WALLET',
                          payload: {
                            data: response.data
                          }
                        });
                      }
                    })
                    .catch((error) => {
                      let err = { componentName: 'PaymentMethod', statusCode: error.response.status, data: error.response.data };
                      store.dispatch(setError(err));
                    })
       
      break;
      case "DELETE_COMPANY_WALLET":
                endpoint = microServices.base + '/v1/company/payment/'+ action?.payload?.paymentGateway;
              
          store.dispatch(isLoader(true));
          APIservice
              .delete(endpoint +'/'+ action.payload.company_wallet_id)
              .then((response) => {
                  store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                          variant: 'success',
                          message: 'Payment configuration deleted successfully.',
                          componentName: 'DeleteWallet'
                      }
                  });
              })
              .catch((error) => {
                  if (error.response.status !== 422) {
                      store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                              variant: 'danger',
                              message: 'Unable to delete configuration!'
                          }
                      });
                  } else {
                      let err = { componentName: 'DeleteWallet', statusCode: error.response.status, data: error.response.data };
                      store.dispatch(setError(err));
                  }
              })
              .finally(() => {
                  store.dispatch(isLoader(false));
              })
        break;

      case "FETCH_COMPANY_STRIP_DETAIL":
        const endpointCompStrip= microServices.base + '/v1/company/payment/stripe';
        store.dispatch(isLoader(true));
        APIservice
          .get(endpointCompStrip)
          .then((response) => {
            if (response && response.data) {
              store.dispatch({
                type: 'SET_COMPANY_STRIP',
                payload: {
                  data: response.data
                }
              });
            }
          })
          .catch((error) => {
            let err = { componentName: 'SetCompanyStrip', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          })  
          .finally(()=>{
            store.dispatch(isLoader(false));
          })

        break;

        case "STORE_COMPANY_STRIP":
          store.dispatch(isLoader(true));
          endpoint  = microServices.base+'/v1/company/payment/stripe';
          APIservice
          .post(endpoint, action.payload.formData)
          .then((response)=>{
            if (response) {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'success',
                  message: `Connected Successfully`,
                  componentName:'ConnectedCleanerStrip',
                  data : response.data.data
                }
              });
            }
          })
          .catch((error)=>{
            let err = {componentName:'errorCleanerStrip',statusCode: error.response.status,data: error.response.data};
            store.dispatch(setError(err));
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        case "UPDATE_COMPANY_STRIP":
          endpoint  = microServices.base+'/'+action.payload.url;
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint+'/'+action.payload.cmp_strip_id, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: action.payload.message,
                  componentName:action.payload.componentName,
                  data : response.data.data
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to update Payment Mode!',
                  }
                });
              } else {
                let err = {componentName:'errorCleanerStrip',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "ENABLE_DISABLE_TILLED_AUTOPAY":
          endpoint = microServices.base + '/v1/company/payment';
          store.dispatch(isLoader(true));
          APIservice
              .put(`${endpoint}/${action.payload.gtwType}/${action.payload.apiName}/${action.payload.company_wallet_id}`)
              .then((response) => {
                  store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                          variant: 'success',
                          message: ` ${action.payload.message} successfully.`,
                          componentName: 'ENABLE_DISABLE_TILLED_AUTOPAY'
                      }
                  });
              })
              .catch((error) => {
                  if (error.response.status !== 422) {
                      store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                          variant: 'danger',
                          message: `Unable to ${action.payload.message} !`
                          }
                      });
                  } else {
                      let err = { componentName: 'ENABLE_DISABLE_TILLED_AUTOPAY', statusCode: error.response.status, data: error.response.data };
                      store.dispatch(setError(err));
                  }
              })
              .finally(() => {
                  store.dispatch(isLoader(false));
              })
          break;
          case "FETCH_COMPANY_TILLED_DETAILS":
          
          let endPointPaymentwallet =  microServices.base +'/v1/company/payment/tilled-merchant/'+action.payload.id;
          APIservice
              .get(endPointPaymentwallet)
              .then((response) => {
                  if (response && response.data) {
  
                      store.dispatch({
                          type: 'SET_COMPANY_TILLED',
                          payload: {
                              data: response.data
                          }
                      });
                  }
              })
              .catch((error) => {
                  let err = { componentName: 'TilledMerchant', statusCode: error.response.status, data: error.response.data };
                  store.dispatch(setError(err));
              })
          break;
              case "DELETE_COMPANY_TILLED_DETAILS":
      endpoint = microServices.base + '/v1/company/payment/tilled-merchant';
  
      store.dispatch(isLoader(true));
      APIservice
          .delete(endpoint + '/' + action.payload.company_tilled_id)
          .then((response) => {
              store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                      variant: 'success',
                      message: 'Tilled account deleted successfully.',
                      componentName: 'DeleteTilled'
                  }
              });
          })
          .catch((error) => {
              if (error.response.status !== 422) {
                  store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                          variant: 'danger',
                          message: 'Unable to delete account!'
                      }
                  });
              } else {
                  let err = { componentName: 'DeleteTilled', statusCode: error.response.status, data: error.response.data };
                  store.dispatch(setError(err));
              }
          })
          .finally(() => {
              store.dispatch(isLoader(false));
          })
      break;
          case "CREATE_MERCHANT_ACCOUNT":
          endpoint = microServices.base + '/v1/company/payment/tilled-merchant';
          store.dispatch(isLoader(true));
  
          APIservice
              .post(endpoint, action.payload)
              .then((response) => {
                  if (response) {
                      store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                              variant: 'success',
                              message: `Account create Successfully `,
                              componentName: 'create merchant'
                          }
                      });
                  }
              })
              .catch((error) => {
  
                  let err = { componentName: 'create merchant', statusCode: error.response.status, data: error.response.data.message };
                  store.dispatch(setError(err));
  
              })
              .finally(() => {
                  store.dispatch(isLoader(false));
              })
          break;
        case "FETCH_TILLED_PRODUCT_CODES":
              let endPointProductCode = microServices.base + '/v1/company/payment/tilled-merchant/product-codes';
              APIservice
                  .get(endPointProductCode)
                  .then((response) => {
                      if (response && response.data) {
  
                          store.dispatch({
                              type: 'SET_TILLED_PRODUCT_CODES',
                              payload: {
                                  data: response.data
                              }
                          });
                      }
                  })
                  .catch((error) => {
                      let err = { componentName: 'TilledProductCodes', statusCode: error.response.status, data: error.response.data };
                      store.dispatch(setError(err));
                  })
              break;

        case "UPDATE_PRODUCT_CODES":
              endpoint = microServices.base + '/v1/company/payment/tilled-merchant';
              store.dispatch(isLoader(true));
              APIservice
                  .put(endpoint + '/' + action.payload.company_id, action.payload.formData)
                  .then((response) => {
                      store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                              variant: 'success',
                              message: 'Product codes updated successfully!',
                              componentName: 'tilledProduct'
                          }
                      });
                  })
                  .catch((error) => {
                    if (error.response.status == 422) {
                      store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                              variant: 'danger',
                              message: 'Tilled services are suspended for new company.'
                          }
                      });
                  } else {
                    let err = { componentName: 'tilledProduct', statusCode: error.response.status, data: error.response.message };
                    store.dispatch(setError(err));

                  }

                  })
                  .finally(() => {
                      store.dispatch(isLoader(false));
                  })
              break;
        case "UPDATE_FULLY_PAID_STATUS":
              store.dispatch(isLoader(true));
              endpoint  = microServices.base+'/v1/payroll/markFullyPaid';
              APIservice
              .post(endpoint, action.payload.formData)
              .then((response)=>{
                if (response) {
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant: 'success',
                      message: action?.payload?.sucessMessage,
                      componentName:'ViewPayroll',
                      data : response.data.data
                    }
                  });
                }
              })
              .catch((error)=>{
                let err = {componentName:'ViewPayroll',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
                
        case "DELETE_PAYROLL":
              endpoint = microServices.base + '/v1/payroll/delete';
              store.dispatch(isLoader(true));
              APIservice
              .post(endpoint, action.payload.formData)
              .then((response) => {
                  if(response) {
                  store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                          variant: 'success',
                          message: action.payload.sucessMessage,
                          componentName: 'DeletePayroll'
                      }
                    });
                  }
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                                variant: 'danger',
                                message: 'Unable to delete payroll!'
                            }
                        });
                    } else {
                        let err = { componentName: 'DeletePayroll', statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
                  break;
          case "GET_TRANSACTION_DETAILS":
                let payroll_id = action.payload.payroll_id;
                endpoint = microServices.base + '/v1/payroll/'+payroll_id+'/transactionDetails';
                APIservice
                    .get(endpoint)
                    .then((response) => {
                        if (response && response.data) {
        
                            store.dispatch({
                                type: 'SET_TRANSACTION_DETAILS',
                                payload: {
                                    data: response.data
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        let err = { componentName: 'GetTransactionDetails', statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                    })
                break;
                case "SAVE_TRANSACTION_DETAILS":
                  endpoint = microServices.base + '/v1/payroll/transactionDetail';
                  store.dispatch(isLoader(true));
          
                  APIservice
                      .post(endpoint, action.payload)
                      .then((response) => {
                          if (response) {
                              store.dispatch({
                                  type: 'SHOW_ALERT',
                                  payload: {
                                      variant: 'success',
                                      message: `Transaction create Successfully `,
                                      componentName: 'createTransaction'
                                  }
                              });
                          }
                      })
                      .catch((error) => {
          
                          let err = { componentName: 'createTransaction', statusCode: error.response.status, data: error.response.data};
                          store.dispatch(setError(err));
          
                      })
                      .finally(() => {
                          store.dispatch(isLoader(false));
                      })
                      break;
                      case "SEND_EMAIL":
                        store.dispatch(isLoader(true));
                        endpoint  = microServices.base+'/v1/payroll/sendEmail';
                        APIservice
                        .post(endpoint, action.payload.formData)
                        .then((response)=>{
                          if (response) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'success',
                                message: action?.payload?.sucessMessage,
                                componentName:'SendEmail',
                                data : response.data.data
                              }
                            });
                          }
                        })
                        .catch((error)=>{
                          let err = {componentName:'SendEmail',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                      break;
                      
                      case "GET_PAYMENT_GATEWAYS":
                       
                        endpoint = microServices.base + '/v1/dropdown/payment-gateway';
                        APIservice
                            .get(endpoint)
                            .then((response) => {
                                if (response && response.data) {
                
                                    store.dispatch({
                                        type: 'SET_PAYMENT_GATEWAYS',
                                        payload: {
                                            data: response.data
                                        }
                                    });
                                }
                            })
                            .catch((error) => {
                                let err = { componentName: 'GetPaymentGtw', statusCode: error.response.status, data: error.response.data };
                                store.dispatch(setError(err));
                            })
                        break;   

                case "MARK_INVOICE_FULLYPAID":
                    endpoint = microServices.base + "/v1/invoice/markpaid/all";
                    APIservice
                      .put(endpoint, action.payload)
                      .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            message: "Invoice marked as fully paid successfully",
                            componentName: "MarkInvoiceFullyPaid",
                          },
                        });
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: "Unable to mark fully paid!",
                            },
                          });
                        } else {
                          let err = {
                            componentName: "MarkInvoiceFullyPaid",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                    break;
                case "DELETE_INVOICE":
                  endpoint = microServices.base + "/v1/invoice/delete";
                  APIservice
                    .put(endpoint, action.payload)
                    .then((response) => {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "success",
                          message: action.payload.sucessMessage,
                          componentName: "InvoiceDeleted",
                        },
                      });
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "danger",
                            message: "Unable to delete invoice!",
                          },
                        });
                      } else {
                        let err = {
                          componentName: "InvoiceDeleted",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;
                  case "SAVE_INVOICE_TRANSACTION_DETAILS":
                    endpoint = microServices.base + '/v1/invoice/transactionDetail';
                    store.dispatch(isLoader(true));
                    APIservice
                        .post(endpoint, action.payload)
                        .then((response) => {
                            if (response) {
                                store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant: 'success',
                                        message: `Transaction created Successfully `,
                                        componentName: 'createInvoiceTransaction'
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            let err = { componentName: 'createInvoiceTransaction', statusCode: error.response.status, data: error.response.data};
                            store.dispatch(setError(err));
                        })
                        .finally(() => {
                            store.dispatch(isLoader(false));
                        })
                    break;

                  case 'CREATE_PAYROLL_LISTING':
                    const appointmentEndpoint = microServices.base + '/v1/payroll/prepare/appointment' + action.payload.searchEndpoint + '&per_page=10000&page=1';
                    const workorderEndpoint = microServices.base + '/v1/payroll/prepare/workorder' + action.payload.searchEndpoint + '&per_page=10000&page=1';
                    store.dispatch(isLoader(true));
                    let apptWorkorderList={};
                    Promise.all([APIservice.get(appointmentEndpoint), APIservice.get(workorderEndpoint)])
                    .then((response) => {
                      if(response) {
                        const [apptResult, workorderResult] = response;
                        const list = [...apptResult.data.data, ...workorderResult.data.data];
                        const total = apptResult.data.meta.total + workorderResult.data.meta.total;
                        apptWorkorderList = {list, total};
                      }
                      store.dispatch({ 
                        type: 'SET_APPT_WORKORDER_LIST',
                        payload: apptWorkorderList,
                      });
                    })
                    .catch((error) => {})
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    })
                  break;
            case "PRINT_PDF":
            endpoint = action?.payload?.selectedLanguage ? microServices.base + '/v1/'+action?.payload?.selectedLanguage+'/payroll/downloadPdf' : microServices.base + '/v1/payroll/downloadPdf';
            store.dispatch(isLoader(true));
            APIservice.
            getBlobDataForMultipleRecord(endpoint, action.payload.formData)
            .then((response)=>{
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                   const link = document.createElement('a');
                   link.href = blobUrl;
                   link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Document"}_${Date.now()}.pdf`);
                   document.body.appendChild(link);
                   link.click(); 
                   link.parentNode.removeChild(link);
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Report!',
                        }
                    });
                } else {
                    let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            });
        break;
                    case "EMAIL_INVOICES":
                      store.dispatch(isLoader(true));
                      endpoint  = microServices.base+action.payload.url;
                      APIservice
                      .post(endpoint, action.payload.invArray)
                      .then((response)=>{
                        if (response) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              componentName:'emailInvoices',
                              data : response.data.data
                            }
                          });
                        }
                      })
                      .catch((error)=>{
                        let err = {componentName:'emailInvoices',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                  break;

                  case "UPDATE_INVOICE_DETAILS":
                    endpoint = microServices.base + '/v1/invoice';
                    store.dispatch(isLoader(true));
                    APIservice
                        .post(endpoint+'/'+action.payload.id, 
                        action.payload.formData)
                        .then((response) => {
                            if (response) {
                                store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant: 'success',
                                        message: `Invoice updated Successfully `,
                                        componentName: 'invoiceUpdated'
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            let err = { componentName: 'invoiceUpdated', statusCode: error.response.status, data: error.response.data};
                            store.dispatch(setError(err));
                        })
                        .finally(() => {
                            store.dispatch(isLoader(false));
                        })
                    break;

                    case "GET_AUTO_PAY_REQUEST":
                      endpoint = microServices.base + '/v1/company/manager/associates/auto-pay-requests';
                      APIservice
                          .get(endpoint)
                          .then((response) => {
                              if (response && response.data) {
              
                                  store.dispatch({
                                      type: 'SET_AUTO_PAY_REQUEST',
                                      payload: {
                                          data: response.data
                                      }
                                  });
                              }
                          })
                          .catch((error) => {
                              let err = { componentName: 'GetAutoPay', statusCode: error.response.status, data: error.response.data };
                              store.dispatch(setError(err));
                          })
                    break;

                    case "DECLINE_AUTO_PAY_REQUEST":
                      let req_id = action.payload.req_id; 
                      endpoint = microServices.base + "/v1/company/payment/tilled/auto-pay/decline/"+req_id;
                      APIservice
                        .put(endpoint, action.payload)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message:action.payload.successMsg,
                              componentName: "AutopayRequestDecline",
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: action.payload.failedMsg,
                              },
                            });
                          } else {
                            let err = {
                              componentName: "AutopayRequestDecline",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;

                      case "SAVE_TILLED_AUTO_PAY_REQUEST":
                        endpoint = microServices.base + '/v1/company/payment/tilled/auto-pay';
                        store.dispatch(isLoader(true));
                        APIservice
                          .post(endpoint, action.payload.data)
                          .then((response) => {
                            if (response) {
                              store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                  variant: 'success',
                                  message: action.payload.successMsg,
                                  componentName: 'SaveTilledReq'
                                }
                              });
                            }
                          })
                          .catch((error) => {
                           
                            let err = {componentName:'SaveTilledReq',statusCode: error.response.status, data: error.response.data.message};
                            store.dispatch(setError(err));
                          })
                          .finally(() => {
                            store.dispatch(isLoader(false));
                          })
                        break;

                        case "SAVE_ACH_STRIPE":
                          endpoint = microServices.base + '/v1/company/payment/stripe/auto-pay-ach';
                          store.dispatch(isLoader(true));
                          APIservice
                            .post(endpoint, action.payload.formdata)
                            .then((response) => {
                              if (response) {
                                store.dispatch({
                                  type: 'SHOW_ALERT',
                                  payload: {
                                    variant: 'success',
                                    message: action.payload.successMsg,
                                    componentName: 'SaveTilledReq'
                                  }
                                });
                              }
                            })
                            .catch((error) => {
                              let err = { componentName: 'SaveTilledReq', statusCode: error.response.status, data: error.response.data.message };

                              store.dispatch(setError(err));
                            })
                            .finally(() => {
                              store.dispatch(isLoader(false));
                            })
                          break;

                          case "CONFIRM_ACH_STRIPE":
                            endpoint = microServices.base + '/v1/company/payment/stripe/auto-pay/confirm/';
                            endpoint = endpoint+action.payload.id
                            store.dispatch(isLoader(true));
                            APIservice
                              .put(endpoint, action.payload.formdata)
                              .then((response) => {
                                if (response) {
                                  store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                      variant: 'success',
                                      message: action.payload.successMsg,
                                      componentName: 'ConfirmAch',
                                      data:response?.data?.data
                                    }
                                  });
                                }
                              })
                              .catch((error) => {
                                let err = { componentName: 'ConfirmAch', statusCode: error.response.status, data: error.response.data};
                                store.dispatch(setError(err));
                              })
                              .finally(() => {
                                store.dispatch(isLoader(false));
                              })
                            break;
                            case "DISABLE_AUTO_PAY_REQUEST":
                              endpoint = microServices.base + '/v1/company/payment';
                              
                              endpoint = endpoint+'/'+action.payload.type+'/auto-pay/disable/'+action.payload.id
                              store.dispatch(isLoader(true));
                              APIservice
                                .put(endpoint, action.payload.formdata)
                                .then((response) => {
                                  if (response) {
                                    store.dispatch({
                                      type: 'SHOW_ALERT',
                                      payload: {
                                        variant: 'success',
                                        message: action.payload.successMsg,
                                        componentName: 'DisableRequest'
                                      }
                                    });
                                  }
                                })
                                .catch((error) => {
                                  let err = { componentName: 'DisableRequest', statusCode: error.response.status, data: error.response.data};
                                  store.dispatch(setError(err));
                                })
                                .finally(() => {
                                  store.dispatch(isLoader(false));
                                })
                              break;
                              case "DISABLE_PROFILE_AUTO_PAY_REQUEST":
                                endpoint = microServices.base + '/v1/company/payment';
                               
                                endpoint = endpoint+'/'+action.payload.type+'/auto-pay/profile-disable/'+action.payload.id
                                store.dispatch(isLoader(true));
                                APIservice
                                  .put(endpoint, action.payload.formdata)
                                  .then((response) => {
                                    if (response) {
                                      store.dispatch({
                                        type: 'SHOW_ALERT',
                                        payload: {
                                          variant: 'success',
                                          message: action.payload.successMsg,
                                          componentName: 'DisableRequest'
                                        }
                                      });
                                    }
                                  })
                                  .catch((error) => {
                                    let err = { componentName: 'DisableRequest', statusCode: error.response.status, data: error.response.data};
                                    store.dispatch(setError(err));
                                  })
                                  .finally(() => {
                                    store.dispatch(isLoader(false));
                                  })
                                break;
                        
                    case "DOWNLOAD_EXCEL_REPORT":
                      endpoint = action?.payload?.selectedLanguage ? microServices.base + '/v1/'+action?.payload?.selectedLanguage+'/payroll/exportPayroll' : microServices.base + '/v1/payroll/exportPayroll';
                      store.dispatch(isLoader(true));
                      APIservice.
                      getBlobDataForMultipleRecord(endpoint, action.payload.formData)
                      .then((response)=>{
                          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Payroll_summary_"}_${Date.now()}.xlsx`);
                          document.body.appendChild(link);
                          link.click(); 
                          link.parentNode.removeChild(link);
                      })
                      .catch((error)=>{
                          if (error.response.status !== 422) {
                              store.dispatch({ 
                                  type: 'SHOW_ALERT', 
                                  payload: {
                                      variant:'danger',
                                      message: 'Unable to Download Report!',
                                  }
                              });
                          } else {
                              let err = {componentName:'DownloadReportXlsx',statusCode: error.response.status,data: error.response.data};
                              store.dispatch(setError(err));
                          }
                      })
                      .finally(()=>{
                          store.dispatch(isLoader(false));
                      });
                  break;
                  case "GET_STRIPE_AUTO_PAYMENT_DATA":
                    endpoint = microServices.base + '/v1/company/payment/stripe/auto-pay/'+ action.payload.id;
                    APIservice
                        .get(endpoint)
                        .then((response) => {
                            if (response && response.data) {
            
                                store.dispatch({
                                    type: 'SET_AUTO_PAY_DATA',
                                    payload: {
                                        data: response.data
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            let err = { componentName: 'GetAutoPay', statusCode: error.response.status, data: error.response.data };
                            store.dispatch(setError(err));
                        })
                  break;
                  case "GET_TILLED_AUTO_PAYMENT_DATA":
                    endpoint = microServices.base + '/v1/company/payment/tilled/auto-pay/'+ action.payload.id;
                    APIservice
                        .get(endpoint)
                        .then((response) => {
                            if (response && response.data) {
            
                                store.dispatch({
                                    type: 'SET_AUTO_PAY_DATA',
                                    payload: {
                                        data: response.data
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            let err = { componentName: 'GetAutoPay', statusCode: error.response.status, data: error.response.data };
                            store.dispatch(setError(err));
                        })
                  break;
                  case "UPDATE_TILLED_AUTO_PAY_REQUEST":
                        endpoint = microServices.base + '/v1/company/payment/tilled/auto-pay/'+ action.payload.id;
                        store.dispatch(isLoader(true));
                        APIservice
                          .put(endpoint, action.payload.data)
                          .then((response) => {
                            if (response) {
                              store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                  variant: 'success',
                                  message: action.payload.successMsg,
                                  componentName: 'UpdateTilledReq'
                                }
                              });
                            }
                          })
                          .catch((error) => {
                            let err = {componentName:'UpdateTilledReq',statusCode: error.response.status, data: error.response.data.message};
                            store.dispatch(setError(err));
                          })
                          .finally(() => {
                            store.dispatch(isLoader(false));
                          })
                        break;
                  case "STRIPE_PAYMENT_METHOD_SETUP":
                          endpoint = microServices.base + '/v1/company/payment/tilled/auto-pay/'+ action.payload.id;
                          store.dispatch(isLoader(true));
                          APIservice
                            .put(endpoint, action.payload.data)
                            .then((response) => {
                              if (response) {
                                store.dispatch({
                                  type: 'SHOW_ALERT',
                                  payload: {
                                    variant: 'success',
                                    message: action.payload.successMsg,
                                    componentName: 'UpdateTilledReq'
                                  }
                                });
                              }
                            })
                            .catch((error) => {
                              let err = {componentName:'UpdateTilledReq',statusCode: error.response.status, data: error.response.data.message};
                              store.dispatch(setError(err));
                            })
                            .finally(() => {
                              store.dispatch(isLoader(false));
                            })
                          break;
                          case "UPDATE_ACH_STRIPE_METHOD":
                          endpoint = microServices.base + '/v1/company/payment/stripe/auto-pay-ach/'+action.payload.id;
                          store.dispatch(isLoader(true));
                          APIservice
                            .put(endpoint, action.payload.formdata)
                            .then((response) => {
                              if (response) {
                                store.dispatch({
                                  type: 'SHOW_ALERT',
                                  payload: {
                                    variant: 'success',
                                    message: action.payload.successMsg,
                                    componentName: 'updateStripeAch'
                                  }
                                });
                              }
                            })
                            .catch((error) => {
                              let err = { componentName: 'updateStripeAch', statusCode: error.response.status, data: error.response.data.message };

                              store.dispatch(setError(err));
                            })
                            .finally(() => {
                              store.dispatch(isLoader(false));
                            })
                          break;
                          case "DOWNLOAD_AGREEMENT_FILE":
                           endpoint = microServices.base + '/v1/company/cleaner/associates/downloadfile/';
                            store.dispatch(isLoader(true));
                            APIservice.
                            getBlobDataForMultipleRecordPut(endpoint+action.payload.id)
                            .then((response)=>{
                                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = blobUrl;
                                link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Agreement"}_${Date.now()}.pdf`);
                                document.body.appendChild(link);
                                link.click(); 
                                link.parentNode.removeChild(link);
                            })
                            .catch((error)=>{
                                if (error.response.status !== 422) {
                                    store.dispatch({ 
                                        type: 'SHOW_ALERT', 
                                        payload: {
                                            variant:'danger',
                                            message: 'Unable to Download Agreement!',
                                        }
                                    });
                                } else {
                                    let err = {componentName:'DownloadAgreementFile',statusCode: error.response.status,data: error.response.data};
                                    store.dispatch(setError(err));
                                }
                            })
                            .finally(()=>{
                                store.dispatch(isLoader(false));
                            });
                        break;
                        case "ENABLE_AUTOPAY":
                          endpoint = microServices.base + '/v1/company/payment/'+action.payload.gateway+'/auto-pay/enable/'+action.payload.id;
                          store.dispatch(isLoader(true));
                          APIservice
                            .put(endpoint, action.payload?.formdata)
                            .then((response) => {
                              if (response) {
                                store.dispatch({
                                  type: 'SHOW_ALERT',
                                  payload: {
                                    variant: 'success',
                                    message: action.payload.successMsg,
                                    componentName: 'enableAutopay'
                                  }
                                });
                              }
                            })
                            .catch((error) => {
                              let err = { componentName: 'enableAutopay', statusCode: error.response.status, data: error.response.data };

                              store.dispatch(setError(err));
                            })
                            .finally(() => {
                              store.dispatch(isLoader(false));
                            })
                            break;
    case "FETCH_QUICK_BOOK_DETAIL":
      endpoint = microServices.base + '/v1/company/account/quickbook/'+action.payload.reqtype;
      store.dispatch(isLoader(true));
      APIservice
        .get(endpoint)
        .then((response) => {
          if (response && response.data) {
            let tempType = action.payload.reqtype=='companyInfo'?'SET_QB_COMPANYINFO':'SET_QUICK_BOOK';
            store.dispatch({
              type: tempType,
              payload: {
                data: response.data
              }
            });
            if(action.payload.reqtype=='revokeTocken'){
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'success',
                message: 'QuickBook Disconnected Successfully',
                componentName:'SetQuickBook'
              }
            });
          }
          }
        })
        .catch((error) => {
          let err = { componentName: 'errorQuickBook', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })

      break;
      case "STORE_QUICK_BOOK_CALLBACK":
        store.dispatch(isLoader(true));
        endpoint  = microServices.base+'/v1/company/account/quickbook';
        APIservice
        .post(endpoint, action.payload.formData)
        .then((response)=>{
          if (response) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'success',
                message: `Connected Successfully`,
                componentName:'ConnectedCleanerStrip',
                data : response.data.data
              }
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:'errorQuickBook',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;
    case "ADD_INVOICE_TO_QB":
      endpoint = microServices.base + "/v1/invoice/qb/addinvoice";
      APIservice
        .post(endpoint, action.payload)
        .then((response) => {
          if(response?.data?.data?.errorQb?.errorInvoiceList){
            store.dispatch({
              type: 'SET_QB_ERROR_MESSAGES',
              payload: {
                  data: response?.data?.data
              }
            });
          }else{
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "success",
                message: "Invoice Added to Quick Book",
                componentName: "addInvoiceToQB",
              },
            });
          }
         })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                    variant:'danger',
                    message: 'Unable to Push Invoice!',
                }
            });
        } else {
          let err = { componentName: 'addInvoiceToQB', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;
      case "ADD_PAYROLL_TO_QB":
        endpoint = microServices.base + "/v1/payroll/qb/addpayroll";
        APIservice
          .post(endpoint, action.payload)
          .then((response) => {
            if(response?.data?.data?.errorQb?.errorPayrollList){
              store.dispatch({
                type: 'SET_QB_PAYROLL_ERRORS',
                payload: {
                    data: response?.data?.data
                }
              });
            }else{
              store.dispatch({
                type: "SHOW_ALERT",
                payload: {
                  variant: "success",
                  message: "Payroll Added to Quick Book",
                  componentName: "addPayrollToQB",
                },
              });
            }
          })
          .catch((error) => {
            let err = { componentName: 'addPayrollToQB', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          });
        break;
        case "RESOLVE_INV_CLI_SER_QB":
          endpoint = microServices.base + "/v1/invoice/qb/resolve/"+action.payload.apiType;
          APIservice
            .post(endpoint, action.payload.invoice_id)
            .then((response) => {
              store.dispatch({
                type: "SHOW_ALERT",
                payload: {
                  variant: "success",
                  message: "Resolved Invoice Quick Book",
                  componentName: "resolveInvoiceToQB",
                  invoice_id : action.payload.invoice_id?.invoice_id
                },
              });
            })
            .catch((error) => {
              let err = { componentName: 'resolveInvoiceToQB', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            });
          break;
          case "RESOLVE_PAYROLL":
            endpoint = microServices.base + "/v1/payroll/qb/resolve/vendor";
            APIservice
              .post(endpoint, action.payload.payroll_id)
              .then((response) => {
                store.dispatch({
                  type: "SHOW_ALERT",
                  payload: {
                    variant: "success",
                    message: "Resolved Payroll Quick Book",
                    componentName: "resolveInvoiceToQB",
                  },
                });
              })
              .catch((error) => {
                let err = { componentName: 'resolvePayrollToQB', statusCode: error.response.status, data: error.response.data };
                store.dispatch(setError(err));
              })
              .finally(() => {
                store.dispatch(isLoader(false));
              });
            break;
            case "DOWNLOAD_CREATE_INVOICE_EXCEL":
              endpoint = action.payload.url;
              store.dispatch(isLoader(true));
              APIservice.
              getBlobDataForMultipleRecord(endpoint, action.payload.data)
              .then((response)=>{
                  const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = blobUrl;
                  link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "invoice_list_"}_${Date.now()}.xlsx`);
                  document.body.appendChild(link);
                  link.click(); 
                  link.parentNode.removeChild(link);
              })
              .catch((error)=>{
                  if (error.response.status !== 422) {
                      store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                              variant:'danger',
                              message: 'Unable to Download Report!',
                          }
                      });
                  } else {
                      let err = {componentName:'DownloadReportXlsx',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                  }
              })
              .finally(()=>{
                  store.dispatch(isLoader(false));
              });
          break;
            case "DOWNLOAD_CREATE_PAYROLL_EXCEL_REPORT":
              endpoint = microServices.base + '/v1/'+action.payload.selectedLanguage+'/payroll/prepare/export';
              store.dispatch(isLoader(true));
              APIservice.
              getBlobDataForMultipleRecord(endpoint, action.payload.formData)
              .then((response)=>{
                  const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = blobUrl;
                  link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Payroll List"}.xlsx`);
                  document.body.appendChild(link);
                  link.click(); 
                  link.parentNode.removeChild(link);
              })
              .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Create Payroll Excel Report!',
                        }
                    });
                  } else {
                    let err = {componentName:'DownloadPayrollReportXlsx',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  }
              })
              .finally(()=>{
                  store.dispatch(isLoader(false));
              });
            break;
            case "MARK_INVOICE_UNPAID":
                    endpoint = microServices.base + "/v1/invoice/fullypaid/remove";
                    APIservice
                      .put(endpoint, action.payload)
                      .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            message: "Invoice marked as unpaid successfully",
                            componentName: "MarkInvoiceUnpaid",
                          },
                        });
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: "Unable to mark unpaid!",
                            },
                          });
                        } else {
                          let err = {
                            componentName: "MarkInvoiceUnpaid",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                    break;
                    case "GET_QB_PAYROLL_COA": {
                      endpoint = `${microServices.base}/v1/payroll/qb/quickbookMap/payrollCOA`;
                      store.dispatch(isLoader(true));
                      APIservice.get(endpoint)
                        .then((response) => {
                          if (response && response.data) {
                            store.dispatch({
                              type: "SET_QB_PAYROLL_COA",
                              payload: {
                                data: response.data.data,
                              },
                            });
                          }
                        })
                        .catch((error) => {
                          let err = {
                            componentName: "getPayrollCOA",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }
                    case "STORE_QB_PAYROLL_COA_WO_ITEM": {
                      let endUrl =  microServices.base + "/v1/payroll/qb/qbUpdate/"+action?.payload?.type;
                      APIservice.post(endUrl, action?.payload?.selectedObj)
                        .then((response) => {
                          if (response ) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'success',
                                message: action?.payload?.displayMsg,
                                componentName: 'MappedPayrollCOA',
                              }
                            });
                          }
                        })
                        .catch((error) => {
                          let err = {
                            componentName: "MappedPayrollCOA",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                        break;
                      }
                      case "GET_CARDPOINTE_KEY":
                        const cardPtKey = microServices.base + '/v1/t/companyWalletPaymentType';
                        APIservice
                          .get(endpointPaymentMethod)
                          .then((response) => {
                            if (response && response.data) {
                          
                            
                            store.dispatch({
                              type: 'SET_CARDPOINTE',
                              payload: {
                                data: response?.data
                              }
                            });
                          }
                        })
                        .catch((error) => {
                          let err = { componentName: 'PaymentMethod', statusCode: error.response.status, data: error.response.data };
                          store.dispatch(setError(err));
                        })
                        break; 
                        case "SAVE_CARDPOINTE_AUTO_PAY_REQUEST":
                          endpoint = microServices.base + '/v1/company/payment/cardpointe/auto-pay';
                          store.dispatch(isLoader(true));
                          APIservice
                            .post(endpoint, action.payload.data)
                            .then((response) => {
                              if (response) {
                                store.dispatch({
                                  type: 'SHOW_ALERT',
                                  payload: {
                                    variant: 'success',
                                    message: action.payload.successMsg,
                                    componentName: 'SaveCardpointeReq'
                                  }
                                });
                              }
                            })
                            .catch((error) => {
                             
                              let err = {componentName:'SaveCardpointeReq',statusCode: error.response.status, data: error.response.data.message};
                              store.dispatch(setError(err));
                            })
                            .finally(() => {
                              store.dispatch(isLoader(false));
                            })
                          break;
                          case "GET_CARDPOINTE_AUTO_PAYMENT_DATA":
                            endpoint = microServices.base + '/v1/company/payment/cardpointe/auto-pay/'+ action.payload.id;
                            APIservice
                                .get(endpoint)
                                .then((response) => {
                                    if (response && response.data) {
                    
                                        store.dispatch({
                                            type: 'SET_AUTO_PAY_DATA',
                                            payload: {
                                                data: response.data
                                            }
                                        });
                                    }
                                })
                                .catch((error) => {
                                    let err = { componentName: 'GetAutoPay', statusCode: error.response.status, data: error.response.data };
                                    store.dispatch(setError(err));
                                })
                          break;

                          case "UPDATE_CARDPOINTE_AUTO_PAY_REQUEST":
                            endpoint = microServices.base + '/v1/company/payment/cardpointe/auto-pay/'+ action.payload.id;
                            store.dispatch(isLoader(true));
                            APIservice
                              .put(endpoint, action.payload.data)
                              .then((response) => {
                                if (response) {
                                  store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                      variant: 'success',
                                      message: action.payload.successMsg,
                                      componentName: 'UpdateCardpointeReq'
                                    }
                                  });
                                }
                              })
                              .catch((error) => {
                                let err = {componentName:'UpdateCardpointeReq',statusCode: error.response.status, data: error.response.data.message};
                                store.dispatch(setError(err));
                              })
                              .finally(() => {
                                store.dispatch(isLoader(false));
                              })
                            break;
                            case "FETCH_COMPANY_CARDPOINTE_DETAILS":
          
                            let endPointCardpt =  microServices.base +'/v1/company/payment/copilot/'+action.payload.id;
                            APIservice
                                .get(endPointCardpt)
                                .then((response) => {
                                    if (response && response.data) {
                    
                                        store.dispatch({
                                            type: 'SET_COMPANY_CARDPOINTE',
                                            payload: {
                                                data: response.data
                                            }
                                        });
                                    }
                                })
                                .catch((error) => {
                                    let err = { componentName: 'CardpointeMerchant', statusCode: error.response.status, data: error.response.data };
                                    store.dispatch(setError(err));
                                })
                                break;
                                case "DELETE_COMPANY_CARDPOINTE_DETAILS":
                                endpoint = microServices.base + '/v1/company/payment/copilot';
                                store.dispatch(isLoader(true));
                                store.dispatch(isLoader(true));
                                APIservice
                                    .delete(endpoint + '/' + action.payload.company_cardpointe_id)
                                    .then((response) => {
                                        store.dispatch({
                                            type: 'SHOW_ALERT',
                                            payload: {
                                                variant: 'success',
                                                message: 'Propelr account deleted successfully.',
                                                componentName: 'DeleteCardpointe'
                                            }
                                        });
                                    })
                                    .catch((error) => {
                            if (error.response.status !== 422) {
                              store.dispatch({
                                  type: 'SHOW_ALERT',
                                  payload: {
                                      variant: 'danger',
                                      message: 'Unable to delete account!'
                                  }
                              });
                          } else {
                              let err = { componentName: 'DeleteCardpointe', statusCode: error.response.status, data: error.response.data };
                              store.dispatch(setError(err));
                          }
                      })
                      .finally(() => {
                          store.dispatch(isLoader(false));
                      })
      break;
          case "CREATE_MERCHANT_ACCOUNT_CARDPOINTE":
          endpoint = microServices.base + '/v1/company/payment/copilot';
          store.dispatch(isLoader(true));
  
          APIservice
              .post(endpoint, action.payload)
              .then((response) => {
                  if (response) {
                      store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                              variant: 'success',
                              message: `Account create Successfully `,
                              componentName: 'create merchant',
                              data:response?.data?.data
                          }
                      });
                  }
              })
              .catch((error) => {
                
                  store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      variant: "danger",
                      message: `Account creation failed`,
                      componentName: 'create merchant',
                    },
                  });
                
              })
              .finally(() => {
                  store.dispatch(isLoader(false));
              })
          break;
          case "GET_CARDPOINTE_ONBORDING_URL":

          let endPointSignature =  microServices.base +'/v1/company/payment/copilot/onbording/'+action.payload.id;
          store.dispatch(isLoader(true));
          APIservice
              .get(endPointSignature)
              .then((response) => {
                  if (response && response.data) {
  
                      store.dispatch({
                          type: 'SET_COMPANY_CARDPOINTE_SIGNATURE',
                          payload: {
                              data: response.data
                          }
                      });
                  }
              })
              .catch((error) => {
                  let err = { componentName: 'CardpointeSignature', statusCode: error.response.status, data: error.response.data };
                  store.dispatch(setError(err));
              })
              .finally(() => {
                store.dispatch(isLoader(false));
            })
          break;
          case "UPDATE_PROPELR_DATA":
            endpoint = microServices.base + '/v1/company/payment/copilot';
            store.dispatch(isLoader(true));
            APIservice
                .put(endpoint + '/' + action.payload.company_id, action.payload.formData)
                .then((response) => {
                    store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                            variant: 'success',
                            message: 'Setting updated successfully!',
                            componentName: 'propelrUpdate'
                        }
                    });
                })
                .catch((error) => {
                    let err = { componentName: 'propelrUpdate', statusCode: error.response.status, data: error.response.message };
                    store.dispatch(setError(err));

                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
                      case "GET_AGREEMENT_REQUEST":
                        endpoint =
                          microServices.base +
                          "/v1/company/manager/associates/agreement-requests";
                        APIservice.get(endpoint)
                          .then((response) => {
                            if (response && response.data) {
                              store.dispatch({
                                type: "SET_AGREEMENT_REQUEST",
                                payload: {
                                  data: response.data,
                                },
                              });
                            }
                          })
                          .catch((error) => {
                            let err = {
                              componentName: "GetAgreement",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          });
                        break;
                        case "ACCEPT_AGREEMENT_REQUEST":
                    let agreement_req_id = action.payload.agreement_id;
                    endpoint =
                      microServices.base +
                      "/v1/company/cleaner/billing-agreement/accept/" +
                      agreement_req_id;
                    APIservice.put(endpoint, action.payload.data)
                      .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            message: action.payload.message,
                            componentName: "AgreementRequestAccept",
                          },
                        });
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: action.payload.failedMsg,
                            },
                          });
                        } else {
                          let err = {
                            componentName: "AgreementRequestAccept",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                    break;
                    case "DECLINE_AGREEMENT_REQUEST":
                      let agreement_id = action.payload.agreement_id;
                      endpoint =
                        microServices.base +
                        "/v1/company/cleaner/billing-agreement/decline/" +
                        agreement_id;
                      APIservice.put(endpoint, action.payload)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: action.payload.message,
                              componentName: "AgreementRequestDecline",
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: action.payload.failedMsg,
                              },
                            });
                          } else {
                            let err = {
                              componentName: "AgreementRequestDecline",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                      case "BILLING_AGREEMENT_DOWNLOAD":
                      store.dispatch(isLoader(true));
                      APIservice.
                      getBlobDataForMultipleRecordPut(action.payload.endpoint)
                      .then((response)=>{
                          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                             const link = document.createElement('a');
                             link.href = blobUrl;
                             link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Document"}_${Date.now()}.pdf`);
                             document.body.appendChild(link);
                             link.click(); 
                             link.parentNode.removeChild(link);
                      })
                      .catch((error)=>{
                          if (error.response.status !== 422) {
                              store.dispatch({ 
                                  type: 'SHOW_ALERT', 
                                  payload: {
                                      variant:'danger',
                                      message: 'Unable to Download Report!',
                                  }
                              });
                          } else {
                              let err = {componentName:'DownloadAgreement',statusCode: error.response.status,data: error.response.data};
                              store.dispatch(setError(err));
                          }
                      })
                      .finally(()=>{
                          store.dispatch(isLoader(false));
                      });
                  break;
                  case "API_CREDENTILAS_CARDPOINTE":
                    store.dispatch(isLoader(true));
                    let company_id = action.payload?.company_id;
                    endpoint =microServices.base+'/v1/company/payment/copilot/account/'+company_id;
                    APIservice.put(endpoint, action.payload?.data)
                      .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            message: action.payload?.message,
                            componentName: "ApiCredentials",
                          },
                        });
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: action.payload?.failedMsg,
                            },
                          });
                        } else {
                          let err = {
                            componentName: "ApiCredentials",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                    break;
                    case "RC_INVOICE_CARDPOINTE_PAYMENT":
                      store.dispatch(isLoader(true));
                      let isFromGlobal =  (action?.payload?.origin) ? true :false;
                      endpoint =microServices.base+'/v1/admin/invoice/payment/cardpointe';
                      APIservice.post(endpoint, action.payload?.data)
                        .then((response) => {
                          if(response.data?.data?.status){
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "success",
                                message: action.payload?.message,
                                componentName: "RcInvoiceCardpointePayment",
                                data: response.data?.data,
                                invoice_id:response.data?.data?.data?.invoice_id,
                                isFromGlobal : isFromGlobal,
                              },
                            });
                          }else{
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: action.payload?.failedMsg,
                              },
                            });
                          }
                         
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: action.payload?.failedMsg,
                              },
                            });
                          } else {
                            let err = {
                              componentName: "RcInvoiceCardpointePayment",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                      case "SAVE_CARDPOINTE_RC_PAYMENT_REQUEST":
                        store.dispatch(isLoader(true));
                        endpoint =microServices.base+'/v1/admin/invoice/payment/cardpointe/savemethod';
                        APIservice.post(endpoint, action.payload?.data)
                          .then((response) => {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "success",
                                message: action.payload?.successMsg,
                                componentName: "propelrPaymentGateway",
                              },
                            });
                          })
                          .catch((error) => {
                            if (error.response.status !== 422) {
                              store.dispatch({
                                type: "SHOW_ALERT",
                                payload: {
                                  variant: "danger",
                                  message: action.payload?.failedMsg,
                                },
                              });
                            } else {
                              let err = {
                                componentName: "propelrPaymentGateway",
                                statusCode: error.response.status,
                                data: error.response.data,
                              };
                              store.dispatch(setError(err));
                            }
                          })
                          .finally(() => {
                            store.dispatch(isLoader(false));
                          });
                        break;
                        case "SYNC_MERCHANT_ACCOUNT_CARDPOINTE":
                          endpoint = microServices.base + '/v1/company/payment/copilot/sync';
                          store.dispatch(isLoader(true));
                          APIservice
                              .post(endpoint, action.payload?.data)
                              .then((response) => {
                                  if (response) {
                                    if(response.data?.success){
                                      store.dispatch({
                                        type: 'SHOW_ALERT',
                                        payload: {
                                            variant: 'success',
                                            message: action.payload?.successMsg,
                                            componentName: 'syncMerchant',
                                            data:response?.data?.data
                                        }
                                    });
                                    }else{
                                     
                                      store.dispatch({
                                        type: 'SHOW_ALERT',
                                        payload: {
                                            variant: 'danger',
                                            message: '',
                                            componentName: 'syncMerchantError',
                                            data:response.data?.data?.errors
                                        }
                                    });
                                    }
                                     
                                  }
                              })
                              .catch((error) => {
                                if (error.response.status == 422) {
                                  let errMsg = error.response?.data?.data?.merchant_id[0];
    
                                  let msg = (errMsg=='ACCOUNT_ALREADY_USED') ? action.payload?.cardpointeAccoutExist : 'Account sync failed!';
                                  store.dispatch({
                                    type: "SHOW_ALERT",
                                    payload: {
                                      variant: "danger",
                                      message: msg,
                                      componentName: 'syncMerchant',
                                    },
                                  });
                                } else {
                                  store.dispatch({
                                    type: "SHOW_ALERT",
                                    payload: {
                                      variant: "danger",
                                      message: `Account sync failed!`,
                                      componentName: 'syncMerchant',
                                    },
                                  });
                                }
                              })
                                 
                              .finally(() => {
                                  store.dispatch(isLoader(false));
                              })
                              break;

                              case "UPDATE_PAYROLL_DETAILS":
                    endpoint = microServices.base + '/v1/payroll';
                    store.dispatch(isLoader(true));
                    APIservice
                        .post(endpoint+'/'+action.payload.id, 
                        action.payload.formData)
                        .then((response) => {
                            if (response) {
                                store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant: 'success',
                                        message: `Payroll updated Successfully `,
                                        componentName: 'payrollUpdated'
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            let err = { componentName: 'payrollUpdated', statusCode: error.response.status, data: error.response.data};
                            store.dispatch(setError(err));
                        })
                        .finally(() => {
                            store.dispatch(isLoader(false));
                        })
                    break; 
                    case "MANAGER_ENABLE_AUTOPAY":
                      endpoint = microServices.base + '/v1/company/manager/associates/autopay/'+action.payload.managerCleanerId;
                      store.dispatch(isLoader(true));
                      APIservice
                        .post(endpoint, action.payload?.formdata)
                        .then((response) => {
                          if (response) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'success',
                                message: action.payload.successMsg,
                                componentName: 'managerEnableAutoPay'
                              }
                            });
                          }
                        })
                        .catch((error) => {
                          let err = { componentName: 'managerEnableAutoPay', statusCode: error.response.status, data: error.response.data };

                          store.dispatch(setError(err));
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        })
                        break;
                    break;
                    case "ACCEPT_AGREEMENT":
                    endpoint = microServices.base + '/v1/company/payment/cardpointe/auto-pay/accept-agreement';
                    APIservice
                        .post(endpoint, action.payload?.data)
                        .then((response) => {
                            if (response) {
                                store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant: 'success',
                                        message: action.payload?.successMsg,
                                        componentName: 'acceptAgreement'
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            let err = { componentName: 'acceptAgreement', statusCode: error.response.status, data: error.response.data};
                            store.dispatch(setError(err));
                        })
                        .finally(() => {
                            store.dispatch(isLoader(false));
                        })
                    break;

    default:
      break;
  }
  return result;
};

export default BillingMiddleware;