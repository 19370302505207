export const getLoggedUserDetails = (data) => {
    return {
        type: 'GET_LOGGED_USER_DETAILS',
        payload: data
    };
}

export const userSignup = (data) => {
    return {
        type: 'USERS_SIGNUP',
        payload: data.formData,
        userType : data.userType
    };
}

export const createStaff = (data) => {
    return {
        type: 'CREATE_STAFF',
        payload: data,
        endpoint:'/v1/staff/create/',
        componentName:'AddStaff',
        userType : data.userType,
        message: 'Staff created successfully!',
        isProfTypeSUP:data.isProfTypeSUP,
        isAccess:data.isAccess,
    };
}

export const activeInactiveStaff = (data) =>{
    return {
        type: 'ACTIVE_INACTIVE_STAFF',
        payload: data,
        endpoint:'/v1/staff',
        userType : data.userType,
        componentName:'ActiveInactiveStaff',
    };
}
export const getStaff = (data) => async (dispatch) =>{
    dispatch({ 
      type: 'FETCH_STAFF',
      payload: data,
      endpoint:'/v1/staff/view',
      userType : data.userType,
      componentName:'Staff',
    });
  }    
  export const updateStaff = (data) => {
    return {
        type: 'UPDATE_STAFF',
        payload: data,
        endpoint:'/v1/staff/update',
        userType : data.userType,
        componentName:'EditStaff',
        isProfTypeSUP:data.isProfTypeSUP,
        isAccess:data.isAccess,
    };
  }

  export const createCleanerManagerRelation = (data) => {
    return {
        type: 'CLEANER_MANAGER_RELATION',
        payload: data.formData,
        userType : data.userType,
        rType : data.rType,
        isLoader: data?.isLoader
    };
 }

    export const getDropdownGrouplist = (data) => {
        return {
            type: 'DROPDOWN_GROUP_LIST',
            payload:data,
        }
    }

    export const getHousekeeperDropdown = (data) => {
        return {
            type: 'HOUSEKEEPER_DROPDOWN',
            payload: data,
        }
    }

    export const createHosekeeperGroup = (data) =>{
        return {
            type: 'CREATE_HOUSEKEEPER_GROUP',
            payload: data,
        }
    }
    export const activeInactiveGroup = (data) =>{
        return {
            type: 'ACTIVE_INACTIVE_GROUP',
            payload: data
        };
    }
    export const getGroup = (data) => async (dispatch) =>{
        dispatch({ 
          type: 'FETCH_GROUP',
          payload:data
        });
    }
    export const updateGroup = (data) => {
        return {
            type: 'UPDATE_GROUP',
            payload: data
        };
    }
    export const updateCleanerManagerRelation = (data) => {
        return {
            type: 'UPDATE_CLEANER_MANAGER_RELATION',
            payload: data.formData,
            userType : data.userType,
            id : data.id,
            successMessage : data.successMessage,
            isCardpointeSubmit : data.isCardpointeSubmit,
        };
     }
    export const getCleanerManagerRelation = (data) => {
        return {
            type: 'GET_CLEANER_MANAGER_RELATION',
            payload: data,
        };
    }

    export const getUserAccessDetails = (data) => {
        return {
            type: 'GET_USER_ACCESS_DETAILS',
            payload: data
        };
    }

    export const getUser = (data) => async (dispatch) =>{
        dispatch({ 
          type: 'FETCH_USER',
          payload:data
        });
    }
      
      export const getCompany = (data) => async (dispatch) =>{
        dispatch({ 
          type: 'FETCH_COMPANY',
          payload: data,
          endpoint:'/v1/company/',
          company_id : data.company_id,
          componentName:'Company',
        });
      }  
      export const UpdateMyAccountDetail = (data) => async (dispatch) =>{
        dispatch({ 
            type: 'UPDATE_USER',
            payload: data,
            id:data.id,
            endpoint:'/v1/user/',
            componentName:'EditUser',
        });
    }

    export const getHkWithRegionArea = () => {
        return {
            type: 'HOUSEKEEPER_DROPDOWN_WITH_REGION_AREA'
            
        }
    }
    export const uploadPropertyManager = (data) =>{
        return {
            type: 'INITIALIZE_PROPERTY_MANAGER_UPLOAD',
            payload: data,
        }
    }

    export const sendWelcomeEmail = (data) =>  async (dispatch) => {
        dispatch( {
            type: 'SEND_WELCOME_EMAIL',
            payload: data,
        });
    }
    
    export const sendVerificationSms = (data, callback = null) =>  async (dispatch) => {
        dispatch( {
            type: 'SEND_VERIFICATION_SMS',
            payload: data,
            callback: callback,
        });
    }
  
    export const getStaffsWithRegionArea = (data) => {
        return {
            type: 'ALL_STAFF_DROPDOWN_WITH_REGION_AREA',
            payload : data
        }
    }
    export const getCompanyStaffs = (data) => {
        return {
            type: 'COMPANY_STAFF_DROPDOWN',
            payload: data,
        };
    }
    export const getInspectorsDropdown = (data) => {
        return {
            type: 'INSPECTORS_DROPDOWN',
            payload:data,
        }
    }
    export const getHousekeeperStaffsDropdown = (data) => {
        return {
            type: 'HOUSEKEEPER_STAFF_DROPDOWN',
            payload: data,
        };
    }

    export const userImpersonate = (data) => {
        return {
            type: 'USER_IMPERSONATE',
            payload: data,
        };
    }

    export const staffChangePassword = (data) => {
        return {
            type: 'STAFF_CHANGE_PASSWORD',
            payload: data,
        };
    }

    export const exportStaff= (data) =>{
        return {
            type: 'EXPORT_STAFF',
            payload: data
        }
    }

    export const addRegionAreaConfig = (data) =>{
        return {
            type: 'ADD_REGION_AREA_CONFIG',
            payload: data
        };
    }

    export const getQuickBookVendorList =(data)=>{
        return {
            type: 'GET_QUICKBOOK_VENDOR',
            payload: data
        };
    }

    export const staffMappedToQbVendor =(data)=>{
        return{
            type:'STORE_QUICKBOOK_VENDOR',
            payload:data
        };
    }

    export const getWoHouskeepersWithAssignedCount = (data) => {
        return {
          type: "GET_WO_HOUSEKEEPERS_WITH_ASSIGNED_COUNT",
          payload: data,
        };
    }

    export const sendAppCredentials = (data) => {
      return {
        type: "SEND_APP_CREDENTIALS",
        payload: data,
      };
    };

    export const updateUserPreferences = (data) => {
        return {
            type: 'UPDATE_USER_PREFERENCES',
            payload: data
        };
    }
    
    export const uploadStaff = (data) => {
        return {
            type: 'UPLOAD_STAFF_INITIALIZE',
            payload: data
            
        };
        
    }

    export const uploadTempStaff = (data) => {
        return {
            type: 'UPLOAD_STAFF',
            payload: data
        };
        
    }

    export const exportSkippedStaff = (data) => {
        return {
            type: 'EXPORT_SKIPPED_STAFF',
            payload: data
        };
    }

    //Impersonate logout start 29-oct-2024
    export const userImpersonateLogout = (data) => {
        return {
            type: 'USER_IMPERSONATE_LOGOUT',
            payload: data,
        };
    }
    //Impersonate logout end 29-oct-2024

    export const updateDnbAlertDismissed = (data) => {
        return {
            type: 'UPDATE_DNB_ALERT_DISMISSED',
            payload: data
        };
    }

    export const updateDnbAlertDismissedState = (dismissStatus) => {
        return {
          type: 'UPDATE_DNB_ALERT_DISMISSED_STATE',
          payload: dismissStatus,
        };
    }

     export const updateAutopayRequestSent = (data) => {
        return {
            type: 'UPDATE_AUTO_PAY_REQUEST_SENT',
            payload: data.formData,
            id : data.id,
            successMessage : data.successMessage,
        };
    }
