export const createTemplate = (data) => {
    return {
        type: 'CREATE_TEMPLATE',
        payload: data,
        endpoint:'/v1/template',
        componentName:'AddInspectionTemplate',
        message: 'Template created successfully!',
    };
}

export const activeInactiveTemplate = (data) =>{
    return {
        type: 'ACTIVE_INACTIVE_TEMPLATE',
        payload: data,
        endpoint:'/v1/template',
        componentName:'ActiveInactiveTemplate',
    };
}
export const getInspTemplateWithRegionArea = (data) => {
  return {
      type: 'INSPECTION_TEMPLATE_DROPDOWN_WITH_REGION_AREA',
      payload : data
  }
}

export const getTemplate = (data) => async (dispatch) =>{
    dispatch({ 
      type: 'FETCH_TEMPLATE_DETAIL',
      payload:data
    });
}

export const getEditTemplateRegionAreaAccess = (data) => async (dispatch) =>{
    dispatch({ 
      type: 'VIEW_TEMPLATE_REGION_AREA_ACCESS',
      payload:data
    });
}

export const getCategory= (data) => async (dispatch) =>{
  dispatch({ 
    type: 'FETCH_TEMPLATE_CATEGORIES',
    payload:data
  });
}

export const getSingleCategory = (data) =>{
  return {
    type: 'GET_TEMPLATE_CATEGORY',
    payload:data
  }
} 

export const getItems = (data) => async (dispatch) =>{
  dispatch({
    type: 'FETCH_TEMPLATE_ITEMS',
    payload:data
  });
}

export const updateTemplate = (data) => {
  return {
      type: 'UPDATE_TEMPLATE',
      payload: data,
  };
}

export const updateRegionArea = (data) => {
  return {
    type: 'UPDATE_TEMPLATE_REGION_AREA',
    payload: data,
  };
}

export const updateTemplateCategory = (data) => {
  return {
      type: 'UPDATE_TEMPLATE_CATEGORY',
      payload: data,
  };
}

export const addTemplateCategory = (data) => {
  return {
      type: 'ADD_TEMPLATE_CATEGORY',
      payload: data,
  };
}

export const deleteTemplateCategory = (data) => async (dispatch) =>{
  dispatch({
    type: 'DELETE_TEMPLATE_CATEGORY',
    payload:data
  });
}

export const createTemplateItem = (data) =>{
  return {
    type: 'ADD_TEMPLATE_ITEM',
    payload: data,
  };
}

export const updateTemplateItem = (data) =>{
  return {
    type: 'UPDATE_TEMPLATE_ITEM',
    payload: data,
  };
}

export const deleteItem = (data) => async (dispatch) => {
  dispatch({
    type: 'DELETE_TEMPLATE_ITEM',
    payload:data
  });
}

export const initializeItemPhotos = (data) => {
  return {
      type: 'INITIALIZE_ITEM_PHOTOS',
      payload: data
  };
}

export const deleteItemImage = (data) => {
  return {
      type: 'DELETE_ITEM_IMAGE',
      payload: data
  }
}

export const cloneTemplate = (data) => {
  return {
      type: 'CLONE_TEMPLATE',
      payload: data,
      endpoint:'/v1/template/',
      componentName:'CloneTemplate',
      message: 'Template Cloned successfully!',
  };
}

export const updateTemplateItemDisplayOrder = (data) =>{
  return {
    type: 'UPDATE_TEMPLATE_ITEM_DISPLAY_ORDER',
    payload: data,
  };
}

export const updateTemplateCategoryDisplayOrder = (data) =>{
  return {
    type: 'UPDATE_TEMPLATE_CATEGORY_DISPLAY_ORDER',
    payload: data,
  };
}
export const fetchInspectionReport = (data) =>{
  return {
    type: 'FETCH_INSPECTION_REPORT',
    payload: data,
  };
}

export const addItemNotes= (data) =>{
  return {
    type: 'ADD_ITEM_NOTES',
    payload: data,
  };
}

export const deleteItemNotes= (data) =>{
  return {
    type: 'DELETE_ITEM_NOTES',
    payload: data,
  };
}

export const initializeInspectionItemPhotos = (data) => {
  return {
      type: 'INITIALIZE_INSPECTION_ITEM_PHOTOS',
      payload: data
  };
}

export const deleteInspectionItemPhoto = (data) => {
  return {
      type: 'DELETE_INSPECTION_ITEM_PHOTO',
      payload: data
  }
}

export const getInspectionList = (data,apptUrl) => {
  return {
    type: "FETCH_INSPECTION_LIST",
    payload: data,
    apptUrl:apptUrl,
  };
};

export const schedulePropertyInspections = (data) => {
  return {
    type: "SCHEDULE_PROPERTY_INSPECTION",
    payload: data,
  };
};

export const updateInspectionAttribute = (data) => {
  return {
    type: "UPDATE_INSPECTION_ATTRIBUTES",
    payload: data,
  };
};

export const updateInspectionReport = (data) => {
  return {
      type: 'UPDATE_INSPECTION_REPORT',
      payload: data
  }
}

export const updateInspectionPointsReport = (data) => {
  return {
      type: 'UPDATE_INSPECTION_REPORT_POINTS',
      payload: data
  }
}

export const updateItemStatus = (data) => {
  return {
      type: 'UPDATE_ITEM_STATUS',
      payload: data
  }
}

export const updateInspectionStatus = (data) => {
  return {
    type: "UPDATE_INSPECTION_STATUS",
    payload: data,
  };
};

export const massCompleteInspection = (data) => {
  return {
    type: "MASS_COMPLETE_INSPECTION",
    payload: data,
  };
};

export const assignInspectorInspection = (data) => {
  return {
    type: "ASSIGN_INSPECTOR_TO_INSPECTION",
    payload: data,
  };
};

export const assignCallbackInspection = (data) => {
  return {
    type: "ASSIGN_CALLBACK_INSPECTION",
    payload: data,
  };
};

export const fetchFailedItemsCategoryWise = (data) =>{
  return {
    type: 'FETCH_FAILED_ITEMS_CATEGORY_WISE',
    payload: data,
  };
}

export const sendEmailInspectionReport = (data) =>{
  return {
    type: 'SEND_EMAIL_INSPECTION_REPORT',
    payload: data,
  };
}

export const getCallbackInspectionCount = (data) =>{
  return {
    type: 'CALLBACK_INSP_COUNT',
    payload: data,
  };
}

export const massCancelInspection = (data) => {
  return {
    type: "MASS_CANCEL_INSPECTION",
    payload: data,
  };
};

export const enableInspection = (data) =>{
  return {
    type: "ENABLE_INSPECTION",
    payload: data,
  }
}

export const exportInspectionList = (data) => {
  return {
      type: 'EXPORT_INSPECTION_LIST',
      payload: data,
  };
};

export const getHkFailedItems = (data) =>{
  return {
    type: "FETCH_HK_FAILED_ITEMS",
    payload: data,
  }
}

export const removeFailedItems = (data) =>{
  return {
    type: "REMOVED_HK_FAILED_ITEMS",
    payload: data,
  }
}

export const fetchInspectionPhotos = (data) =>{
  return {
    type: "FETCH_INSPECTION_ALL_PHOTOS",
    payload: data,
  }
}

