const initState = {
  quoteRequests: [],
  quoteRequest: {},
};

const QuoteRequest = (state = initState, action) => {
  switch (action.type) {
    case "SET_QUOTE_REQUESTS":
      return {
        ...state,
        quoteRequests: action.payload.data,
      };

    case "SET_QUOTE_REQUEST":
      return {
        ...state,
        quoteRequest: action.payload.data,
      };

    case "CLEAR_QUOTE_REQUESTS":
      return {
        ...state,
        quoteRequests: [],
      };

    case "CLEAR_QUOTE_REQUEST":
      return {
        ...state,
        quoteRequest: {},
      };

    default:
      return state;
  }
};

export default QuoteRequest;
